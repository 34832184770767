import { IconSVGProps } from "./types";

const SortIcon = ({ height = 24, width = 24, className }: IconSVGProps) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 -960 960 960"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M102-185v-106h269v106H102Zm0-265v-106h514v106H102Zm0-265v-106h758v106H102Z" />
    </svg>
);

export default SortIcon;
