import {
    EnrollmentStatusEnum,
    EnrolmentTypeEnum,
    HousingCapacityEnum,
    HousingSortByEnum,
    RoomateTypeEnum,
    SecondaryEducationEnum,
} from "utils/enums";

export const StringResources = {
    modal: {
        close: "modal.close",
        confirm: "modal.confirm",
    },
    header: {
        navLinkHome: "header.navLinkHome",
        navLinkHousing: "header.navLinkHousing",
        navLinkAbout: "header.navLinkAbout",
        navLinkContact: "header.navLinkContact",
    },
    footer: {
        text1: "footer.text1",
        text2: "footer.text2",
        text3: "footer.text3",
        primaryNavTitle: "footer.primaryNavTitle",
        secondaryNavTitle: "footer.secondaryNavTitle",
        primaryNavLinkHome: "footer.primaryNavLinkHome",
        primaryNavLinkHousing: "footer.primaryNavLinkHousing",
        primaryNavLinkAbout: "footer.primaryNavLinkAbout",
        primaryNavLinkContact: "footer.primaryNavLinkContact",
        bottomText: "footer.bottomText",
    },
    settings: {
        navLinkAccount: "header.navLinkAccount",
        navLinkChangePassword: "header.navLinkChangePassword",
        navLinkLogout: "header.navLinkLogout",
    },
    login: {
        subtitle: "login.subtitle",
        title: "login.title",
        formTitle: "login.formTitle",
        formPasswordInputLabel: "login.formPasswordInputLabel",
        formUsernameInputLabel: "login.formEmailInputLabel",
        formButtonSubmit: "login.formButtonSubmit",
        forgotPassword: "login.forgotPassword",
        formUsernameInputError: "login.formUsernameInputError",
        formPasswordInputError: "login.formPasswordInputError",
    },
    resetPassword: {
        subtitle: "resetPassword.subtitle",
        title: "resetPassword.title",
        formTitle: "resetPassword.formTitle",
        formPasswordInputLabel: "resetPassword.formPasswordInputLabel",
        formPasswordInputError: "resetPassword.formPasswordInputError",
        formConfirmPasswordInputLabel: "resetPassword.formConfirmPasswordInputLabel",
        formConfirmPasswordInputError: "resetPassword.formConfirmPasswordInputError",
        formConfirmPasswordMatchError: "resetPassword.formConfirmPasswordMatchError",
        saveButtonLabel: "resetPassword.saveButtonLabel",
        resetPasswordSuccess: "resetPassword.resetPasswordSuccess",
        resetPasswordFail: "resetPassword.resetPasswordFail",
    },
    changePassword: {
        subtitle: "changePassword.subtitle",
        title: "changePassword.title",
        formTitle: "changePassword.formTitle",
        formOldPasswordInputLabel: "changePassword.formOldPasswordInputLabel",
        formOldPasswordInputError: "changePassword.formOldPasswordInputError",
        formPasswordInputLabel: "changePassword.formPasswordInputLabel",
        formPasswordInputError: "changePassword.formPasswordInputError",
        formConfirmPasswordInputLabel: "changePassword.formConfirmPasswordInputLabel",
        formConfirmPasswordInputError: "changePassword.formConfirmPasswordInputError",
        formConfirmPasswordMatchError: "changePassword.formConfirmPasswordMatchError",
        saveButtonLabel: "changePassword.saveButtonLabel",
        changePasswordSuccess: "changePassword.changePasswordSuccess",
        changePasswordFail: "changePassword.changePasswordFail",
    },
    forgotPassword: {
        subtitle: "forgotPassword.subtitle",
        title: "forgotPassword.title",
        formTitle: "forgotPassword.formTitle",
        formEmailInputLabel: "forgotPassword.formEmailInputLabel",
        formEmailRequiredInputError: "forgotPassword.formEmailRequiredInputError",
        formEmailInputError: "forgotPassword.formEmailInputError",
        saveButtonLabel: "forgotPassword.saveButtonLabel",
    },
    createAccount: {
        subtitle: "createAccount.subtitle",
        title: "createAccount.title",
        formNameInputLabel: "createAccount.formNameInputLabel",
        formNameInputRequiredError: "createAccount.formNameInputRequiredError",
        formLastNameInputLabel: "createAccount.formLastNameInputLabel",
        formLastNameInputRequiredError: "createAccount.formLastNameInputRequiredError",
        formEmailInputLabel: "createAccount.formEmailInputLabel",
        formEmailInputRequiredError: "createAccount.formEmailInputRequiredError",
        formEmailInputError: "createAccount.formEmailInputError",
        formPhoneInputLabel: "createAccount.formPhoneInputLabel",
        formPhoneInputRequiredError: "createAccount.formPhoneInputRequiredError",
        formDateInputLabel: "createAccount.formDateInputLabel",
        formDateInputRequiredError: "createAccount.formDateInputRequiredError",
        formUsernameInputLabel: "createAccount.formUsernameInputLabel",
        formUsernameInputRequiredError: "createAccount.formUsernameInputRequiredError",
        formPasswordInputLabel: "createAccount.formPasswordInputLabel",
        formPasswordInputRequiredError: "createAccount.formPasswordInputRequiredError",
        formConfirmPasswordInputLabel: "createAccount.formConfirmPasswordInputLabel",
        formConfirmPasswordInputRequiredError: "createAccount.formConfirmPasswordInputRequiredError",
        formConfirmPasswordMatchError: "createAccount.formConfirmPasswordMatchError",
        formCitizenshipInputLabel: "createAccount.formCitizenshipInputLabel",
        formCitizenshipInputRequiredError: "createAccount.formCitizenshipInputRequiredError",
        formTerms: "createAccount.formTerms",
        formTermsLink: "createAccount.formTermsLink",
        formPrivacy: "createAccount.formPrivacy",
        formPrivacyLink: "createAccount.formPrivacyLink",
        formButtonSubmit: "createAccount.formButtonSubmit",
        createAccountSuccess: "createAccount.createAccountSuccess",
        createAccountError: "createAccount.createAccountError",
        formCollegeInputRequiredError: "createAccount.formCollegeInputRequiredError",
        formEnrollmentTypeInputRequiredError: "createAccount.formEnrollmentTypeInputRequiredError",
        formSecondaryEducationInputRequiredError: "createAccount.formSecondaryEducationInputRequiredError",
        formCollegeProgramInputRequiredError: "createAccount.formCollegeProgramInputRequiredError",
        formEnrollmentStatusInputRequiredError: "createAccount.formEnrollmentStatusInputRequiredError",
        formTermsAgreedInputRequiredError: "createAccount.formTermsAgreedInputRequiredError",
        formPrivacyAgreedInputRequiredError: "createAccount.formPrivacyAgreedInputRequiredError",
        formCollegeInputPlaceholder: "createAccount.formCollegeInputPlaceholder",
        formEnrollmentTypeInputPlaceholder: "createAccount.formEnrollmentTypeInputPlaceholder",
        formSecondaryEducationInputPlaceholder: "createAccount.formSecondaryEducationInputPlaceholder",
        formCollegeProgramInputPlaceholder: "createAccount.formCollegeProgramInputPlaceholder",
        formEnrollmentStatusInputPlaceholder: "createAccount.formEnrollmentStatusInputPlaceholder",
    },
    housing: {
        heroTitle: "housing.heroTitle",
        heroSubitle: "housing.heroSubitle",
        heroText: "housing.heroText",
        filtersTitle: "housing.filtersTitle",
        filterAction: "housing.filterAction",
        moveInDate: "housing.moveInDate",
        moveOutDate: "housing.moveOutDate",
        filterPriceRangeSlider: "housing.filterPriceRangeSlider",
        housingList: {
            sortBy: "housing.housingList.sortBy",
        },
    },
    housingDetails: {
        heroTitlePart: "housingDetails.heroTitlePart",
        heroSubitle: "housingDetails.heroSubitle",
        moveInDate: "housingDetails.moveInDate",
        moveOutDate: "housingDetails.moveOutDate",
        title: "housingDetails.title",
        action: "housingDetails.action",
    },
    housingReservation: {
        heroTitlePart: "housingReservation.heroTitlePart",
        heroTitle: "housingReservation.heroTitle",
        heroSubitle: "housingReservation.heroSubitle",
        moveInDate: "housingReservation.moveInDate",
        moveOutDate: "housingReservation.moveOutDate",
        roommateOption: "housingReservation.roommateOption",
        haveRoommate: "housingReservation.haveRoommate",
        findRoommate: "housingReservation.findRoommate",
        reserveBtn: "housingReservation.reserveBtn",
        checkboxLabel: "housingReservation.checkboxLabel",
        checkboxLink: "housingReservation.checkboxLink",
        backLink: "housingReservation.backLink",
        roommate: "housingReservation.roommate",
        formNameInputLabel: "housingReservation.formNameInputLabel",
        formLastNameInputLabel: "housingReservation.formLastNameInputLabel",
        formEmailInputLabel: "housingReservation.formEmailInputLabel",
        formPhoneInputLabel: "housingReservation.formPhoneInputLabel",
        roomAvailability: "housingReservation.roomAvailability",
        dontWantRoomate: "housingReservation.dontWantRoomate",
        reservationError: "housingReservation.reservationError",
        reservationSuccess: "housingReservation.reservationSuccess",
    },
    profile: {
        sraContactTitle: "profile.sraContactTitle",
        sraContactPhone: "profile.sraContactPhone",
        sraContactEmail: "profile.sraContactEmail",
        praContactTitle: "profile.praContactTitle",
        praContactPhone: "profile.praContactPhone",
        praContactEmail: "profile.psraContactEmail",
        infoBarCheckInDate: "profile.infoBarCheckInDate",
        infoBarCheckOutDate: "profile.infoBarCheckOutDate",
        infoBarInstitution: "profile.infoBarInstitution",
        infoBarProgram: "profile.infoBarProgram",
        tabResidency: "profile.tabResidency",
        tabHousing: "profile.tabHousing",
        tabEducation: "profile.tabEducation",
        tabPayment: "profile.tabPayment",
        tabAccount: "profile.tabAccount",
        tabGeneral: "profile.tabGeneral",
        tabStudent: "profile.tabStudent",
        accountTabTitle: "profile.accountTabTitle",
        accountFormUsernameInputLabel: "profile.accountFormUsernameInputLabel",
        accountFormFirstNameInputLabel: "profile.accountFormFirstNameInputLabel",
        accountFormLastNameInputLabel: "profile.accountFormLastNameInputLabel",
        accountFormEmailInputLabel: "profile.accountFormEmailInputLabel",
        accountFormFirstNameInputPlaceholder: "profile.accountFormFirstNameInputPlaceholder",
        accountFormLastNameInputPlaceholder: "profile.accountFormLastNameInputPlaceholder",
        accountFormUsernameInputPlaceholder: "profile.accountFormUsernameInputPlaceholder",
        accountFormEmailInputPlaceholder: "profile.accountFormEmailInputPlaceholder",
        accountFormNameInputRequiredError: "profile.accountFormNameInputRequiredError",
        accountFormLastNameInputRequiredError: "profile.accountFormLastNameInputRequiredError",
        accountFormEmailInputRequiredError: "profile.accountFormEmailInputRequiredError",
        accountFormEmailInputError: "profile.accountFormEmailInputError",
        accountFormUsernameInputRequiredError: "profile.accountFormUsernameInputRequiredError",
        accountFormPhoneInputLabel: "profile.accountFormPhoneInputLabel",
        accountFormPhoneInputPlaceholder: "profile.accountFormPhoneInputPlaceholder",
        accountFormPhoneInputError: "profile.accountFormPhoneInputError",
        accountFormPhoneInputRequiredError: "profile.accountFormPhoneInputRequiredError",
        accountTermsConditionsCaption: "profile.accountTermsConditionsCaption",
        accountPrivacyPolicyCaption: "profile.accountPrivacyPolicyCaption",
        accountDeactivateAccountCaption: "profile.accountDeactivateAccountCaption",
        accountSaveCaption: "profile.accountSaveCaption",
        accountUploadCaption: "profile.accountUploadCaption",
        paymentTabTitle: "profile.paymentTabTitle",
        paymentTabIBAN: "profile.paymentTabIBAN",
        paymentTabReferenceNumber: "profile.paymentTabReferenceNumber",
        paymentTabAccommodation: "profile.paymentTabAccommodation",
        paymentTabDeposit: "profile.paymentTabDeposit",
        paymentTabAmmount: "profile.paymentTabAmmount",
        paymentTabRent: "profile.paymentTabRent",
        paymentTabUtilities: "profile.paymentTabUtilities",
        paymentTabUtilitiesSeperately: "profile.paymentTabUtilitiesSeperately",
        paymentTabAgencyFees: "profile.paymentTabAgencyFees",
        paymentTabAgencyFeeLandlord: "profile.paymentTabAgencyFeeLandlord",
        paymentTabDepositMessage: "profile.paymentTabDepositMessage",
        paymentTabTotal: "profile.paymentTabTotal",
        paymentTabDownloadPdf: "profile.paymentTabDownloadPdf",
        housingTabTitle: "profile.housingTabTitle",
        housingTabRoommates: "profile.housingTabRoommates",
        housingTabFavoritesTitle: "profile.housingTabFavoritesTitle",
        housingTabFavoritesFallbackMsg: "profile.housingTabFavoritesFallbackMsg",
        housingTabFavoritesAvailability: "profile.housingTabFavoritesAvailability",
        educationTabTableColumnDocument: "profile.educationTabTableColumnDocument",
        educationTabTableColumnFormat: "profile.educationTabTableColumnFormat",
        educationTabTableColumnSpecification: "profile.educationTabTableColumnSpecification",
        educationTabTableColumnPickup: "profile.educationTabTableColumnPickup",
    },
    home: {
        heroTitle: "home.heroTitle",
        heroSubitle: "home.heroSubitle",
        heroActionLabel: "home.heroActionLabel",
        contentTitle: "home.contentTitle",
        contentSubtitle: "home.contentSubtitle",
        contentBlockTitle1: "home.contentBlockTitle1",
        contentBlockTitle2: "home.contentBlockTitle2",
        contentBlockTitle3: "home.contentBlockTitle3",
        contentBlockTitle4: "home.contentBlockTitle4",
        contentBlockText1: "home.contentBlockText1",
        contentBlockText2: "home.contentBlockText2",
        contentBlockText3: "home.contentBlockText3",
        contentBlockText4: "home.contentBlockText4",
        eventsTitle: "home.eventsTitle",
        partnersTitle: "home.partnersTitle",
        partnersText: "home.partnersText",
    },
    contact: {
        title: "contact.title",
        subtitle: "contact.subtitle",
        formTitle: "contact.formTitle",
        formSubtitle: "contact.formSubtitle",
        formNameInput: "contact.formNameInput",
        formEmailInput: "contact.formEmailInput",
        formSubjectInput: "contact.formSubjectInput",
        formPhoneInput: "contact.formPhoneInput",
        formMessageInput: "contact.formMessageInput",
        formNameInputError: "contact.formNameInputError",
        formEmailInputError: "contact.formEmailInputError",
        formEmailRequiredInputError: "contact.formEmailRequiredInputError",
        formSubjectInputError: "contact.formSubjectInputError",
        formPhoneInputError: "contact.formPhoneInputError",
        formMessageInputError: "contact.formMessageInputError",
        formButtonSubmit: "contact.formButtonSubmit",
        formMessage: "contact.formMessage",
        applyForHousingTitle: "contact.applyForHousingTitle",
        housingRentBodyText: "contact.housingRentBodyText",
        housingRentBodyText2: "contact.housingRentBodyText2",
        rentPropertyTitle: "contact.rentPropertyTitle",
    },
    terms: {
        title: "terms.title",
        span: "terms.span",
        titlePart2: "terms.subtitle",
        scopeTitle: "terms.scopeTitle",
        scopeText: "terms.scopeText",
        definitionsTitle: "terms.definitionsTitle",
        definitionsText: "terms.definitionsText",
        edudomTitle: "terms.edudomTitle",
        edudomText: "terms.edudomText",
        meaningTitle: "terms.meaningTitle",
        meaningText: "terms.meaningText",
        termsOfUseTitle: "terms.termsOfUseTitle",
        termsOfUseTitleText: "terms.termsOfUseTitleText",
        userTitle: "terms.userTitle",
        userTitleText: "terms.userTitleText",
        serviceTitle: "terms.serviceTitle",
        serviceTitleText: "terms.serviceTitleText",
        servicesAndCompensationTitle: "terms.servicesAndCompensationTitle",
        servicesAndCompensationText: "terms.servicesAndCompensationText",
        serviceItemA: "terms.serviceItemA",
        serviceItemB: "terms.serviceItemB",
        serviceItemC: "terms.serviceItemC",
        serviceItemD: "terms.serviceItemD",
        servicesChargingText: "terms.servicesChargingText",
        compensationText: "terms.compensationText",
        paymentProcedureText: "terms.paymentProcedureText",
        additionalCostsText: "terms.additionalCostsText",
        activationTitle: "terms.activationTitle",
        ageRequirement: "terms.ageRequirement",
        minorRegistration: "terms.minorRegistration",
        registrationProcedure: "terms.registrationProcedure",
        dataResponsibility: "terms.dataResponsibility",
        privacyPolicy: "terms.privacyPolicy",
        activationDuration: "terms.activationDuration",
        inactivityProcedure: "terms.inactivityProcedure",
        accountDeletion: "terms.accountDeletion",
        postDeletionProcedure: "terms.postDeletionProcedure",
        accountRecreation: "terms.accountRecreation",
        visitorAccess: "terms.visitorAccess",
        liabilityTitle: "terms.liabilityTitle",
        liabilityText: "terms.liabilityText",
        liabilityListA: "terms.liabilityListA",
        liabilityListB: "terms.liabilityListB",
        liabilityListC: "terms.liabilityListC",
        liabilityText2: "terms.liabilityText2",
        nonLiabilityText3: "terms.nonLiabilityText3",
        nonLiabilityListA: "terms.nonLiabilityListA",
        nonLiabilityListB: "terms.nonLiabilityListB",
        nonLiabilityListC: "terms.nonLiabilityListC",
        nonLiabilityListD: "terms.nonLiabilityListD",
        nonLiabilityListE: "terms.nonLiabilityListE",
        nonLiabilityListF: "terms.nonLiabilityListF",
        nonLiabilityListG: "terms.nonLiabilityListG",
        liabilityText3: "terms.liabilityText3",
        liabilityText4: "terms.liabilityText4",
        liabilityText5: "terms.liabilityText5",
        terminationTitle: "terms.terminationTitle",
        terminationText1: "terms.terminationText1",
        terminationText2: "terms.terminationText2",
        terminationText3: "terms.terminationText3",
        terminationText4: "terms.terminationText4",
        customerServiceTitle: "terms.customerServiceTitle",
        customerServiceText: "terms.customerServiceText",
        disputeResolutionTitle: "terms.disputeResolutionTitle",
        disputeResolutionText: "terms.disputeResolutionText",
        miscellaneousTitle: "terms.miscellaneousTitle",
        miscellaneousText: "terms.miscellaneousText",
        termsPublishDate: "terms.termsPublishDate",
    },
    rent: {
        title: "rent.title",
        span: "rent.span",
    },
    privacy: {
        privacyTitle: "privacy.privacyTitle",
        privacyTitle2: "privacy.privacyTitle2",
        privacyText1: "privacy.privacyText1",
        privacyText2: "privacy.privacyText2",
        privacyText3: "privacy.privacyText3",
        privacyText4: "privacy.privacyText4",
        privacyText5: "privacy.privacyText5",
        privacyText6: "privacy.privacyText6",
        privacyText7: "privacy.privacyText7",
        privacyText8: "privacy.privacyText8",
        privacyText9: "privacy.privacyText9",
        privacyText10: "privacy.privacyText10",
        privacyText11: "privacy.privacyText11",
        customerServiceTitle: "privacy.customerServiceTitle",
        customerServiceText1: "privacy.customerServiceText1",
        customerServiceText2: "privacy.customerServiceText2",
        customerServiceText3: "privacy.customerServiceText3",
        customerServiceText4: "privacy.customerServiceText4",
    },
    about: {
        title: "about.title",
        subtitle: "about.subtitle",
        visionTitlePart1: "about.visionTitlePart1",
        visionTitlePart2: "about.visionTitlePart2",
        visionText: "about.visionText",
        studentsTitle: "about.studentsTitle",
        studentsText: "about.studentsText",
        partnersTitle: "about.partnersTitle",
        partnersText: "about.partnersText",
        person1Position: "about.person1Position",
        person2Position: "about.person2Position",
        person3Position: "about.person3Position",
    },
    gdprConsent: {
        text: "gdprConsent.text",
        acceptAction: "gdprConsent.acceptAction",
        settingsAction: "gdprConsent.settingsAction",
        necessaryCookies: "gdprConsent.necessaryCookies",
    },
    enums: {
        roomateTypeEnum: {
            [RoomateTypeEnum.Student]: "enums.roomateTypeEnum.student",
            [RoomateTypeEnum.NotAStudent]: "enums.roomateTypeEnum.notAStudent",
        },
        enrolmentType: {
            [EnrolmentTypeEnum.StateExam]: "enums.enrolmentType.stateExam",
            [EnrolmentTypeEnum.University]: "enums.enrolmentType.university",
        },
        secondaryEducation: {
            [SecondaryEducationEnum.Croatia]: "enums.secondaryEducation.croatia",
            [SecondaryEducationEnum.EU]: "enums.secondaryEducation.eu",
            [SecondaryEducationEnum.NonEU]: "enums.secondaryEducation.nonEu",
        },
        enrollmentStatus: {
            [EnrollmentStatusEnum.Exchange]: "enums.enrollmentStatus.exchange",
            [EnrollmentStatusEnum.LongTerm]: "enums.enrollmentStatus.longterm",
            [EnrollmentStatusEnum.Transfer]: "enums.enrollmentStatus.transfer",
        },
        housingCapacity: {
            [HousingCapacityEnum.Single]: "enums.housingCapacity.single",
            [HousingCapacityEnum.Double]: "enums.housingCapacity.double",
            [HousingCapacityEnum.Triple]: "enums.housingCapacity.triple",
        },
        housingSort: {
            [HousingSortByEnum.Price]: "enums.housingSort.price",
            [HousingSortByEnum.DistanceToUni]: "enums.housingSort.distanceToUni",
            [HousingSortByEnum.Single]: "enums.housingSort.single",
            [HousingSortByEnum.Double]: "enums.housingSort.double",
            [HousingSortByEnum.Triple]: "enums.housingSort.triple",
        },
    },
};
