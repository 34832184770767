import { IconSVGProps } from "./types";

const ListIcon = ({ height = 40, width = 40, className }: IconSVGProps) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 -960 960 960"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M371.334-198.667v-66.666H840v66.666H371.334Zm0-248v-66.666H840v66.666H371.334Zm0-248.667V-762H840v66.666H371.334ZM192.666-160.667q-29.699 0-50.849-20.5-21.15-20.5-21.15-50.499 0-30 20.833-50.834 20.833-20.833 50.833-20.833t50.5 21.15q20.5 21.15 20.5 50.85 0 29.15-20.758 49.908-20.759 20.758-49.909 20.758Zm0-248q-29.699 0-50.849-20.954-21.15-20.954-21.15-50.379 0-29.425 21.15-50.379 21.15-20.954 50.849-20.954 29.15 0 49.909 20.954 20.758 20.954 20.758 50.379 0 29.425-20.758 50.379-20.759 20.954-49.909 20.954ZM192-657.334q-29.425 0-50.379-20.954-20.954-20.954-20.954-50.379 0-29.425 20.954-50.379Q162.575-800 192-800q29.425 0 50.379 20.954 20.954 20.954 20.954 50.379 0 29.425-20.954 50.379-20.954 20.954-50.379 20.954Z"/>
    </svg>
);

export default ListIcon;
