import {FallbackComponent} from "containers/layout/fallbackComponent";
import React, {Suspense, useEffect} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getUserThunk,} from "redux/actions/userAction";
import {RootState, useAppDispatch} from "redux/store";
import {LoginStateEnum} from "utils/enums";

interface IPortalWrapperProps {
    allowLoggedOut?: boolean;
    children: React.ReactNode;
}

const PortalWrapper = (props: IPortalWrapperProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const authDataState = useSelector((state: RootState) => state.auth.loginState);

    useEffect(() => {
        const getUserDetails = async () => {
            await dispatch(getUserThunk()).unwrap();
        }

        if (!props.allowLoggedOut) {
            if (authDataState != LoginStateEnum.LoggedIn) {
                navigate("/login");
                return;
            }
        }

        if (authDataState === LoginStateEnum.LoggedIn) {
            // dispatch(getUserThunk())
            getUserDetails().then();
        }
    }, [authDataState]);

    return (
        <main role="main" className="portal-wrapper">
            <Suspense fallback={<FallbackComponent />}>{props.children}</Suspense>
        </main>
    );
};

export default PortalWrapper;
