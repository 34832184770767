import React, { useEffect, useState } from "react";
import BaseButton from "components/baseButton/baseButton";
import BaseCheckbox from "components/baseCheckbox/baseCheckbox";
import { useTranslation } from "react-i18next";
import { StringResources } from "utils/language/languageResource";
import { CancelIcon } from "components/icons";
import "./gdprConsent.scss";

function setCookie(name: string, value: string, days: number) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value || ""}${expires}; path=/`;
}

function getCookie(name: string) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(";");

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function eraseCookie(name: string) {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

const GdprConsent = () => {
    const { t } = useTranslation();
    const [gdprAccepted, setGdprAccepted] = useState<boolean>(true);
    const [expandedPartMaxHeight, setExpandedPartMaxHeight] = useState<number | undefined>(0);
    const [expandedPartPadding, setExpandedPartPadding] = useState<number>(0);

    useEffect(() => {
        const cookie = getCookie("edudom_gdpr_consent");

        if (cookie !== "granted") {
            setGdprAccepted(false);
        }
    }, []);

    const acceptCookies = () => {
        setCookie("edudom_gdpr_consent", "granted", 60);

        if (document.querySelector(".gdpr-consent")) {
            document.querySelector(".gdpr-consent")?.remove();
        }
    };

    const expandSettings = () => {
        if (expandedPartMaxHeight) {
            setExpandedPartMaxHeight(0);
            setExpandedPartPadding(0);
        } else {
            if (document.querySelector(".gdpr-consent__expanded-part")) {
                setExpandedPartMaxHeight(document.querySelector(".gdpr-consent__expanded-part")?.scrollHeight);
                setExpandedPartPadding(30);
            }
        }
    };

    const closeGdprConseent = () => {
        if (document.querySelector(".gdpr-consent")) {
            document.querySelector(".gdpr-consent")?.remove();
        }
    };

    return !gdprAccepted ? (
        <div className="gdpr-consent">
            <div className="gdpr-consent__content">
                <p className="gdpr-consent__text">{`${t(StringResources.gdprConsent.text)}`}</p>

                <div className="gdpr-consent__actions">
                    <BaseButton
                        className="gdpr-consent__action"
                        styleType={"outline"}
                        size={"small"}
                        text={t(StringResources.gdprConsent.settingsAction)}
                        onClick={expandSettings}
                    />

                    <BaseButton
                        className="gdpr-consent__action"
                        styleType={"solid"}
                        size={"small"}
                        text={t(StringResources.gdprConsent.acceptAction)}
                        onClick={acceptCookies}
                    />
                </div>

                <button type="button" className="gdpr-consent__cancel-action" onClick={closeGdprConseent}>
                    <CancelIcon />
                </button>
            </div>
            <div
                className="gdpr-consent__expanded-part"
                style={{
                    maxHeight: `${expandedPartMaxHeight ? expandedPartMaxHeight + 30 : 0}px`,
                    paddingTop: `${expandedPartPadding}px`,
                }}
            >
                <BaseCheckbox
                    id="necessary"
                    checked={true}
                    disabled={true}
                    label={t(StringResources.gdprConsent.necessaryCookies)}
                ></BaseCheckbox>
            </div>
        </div>
    ) : (
        <></>
    );
};

export default GdprConsent;
