import AppHeader from "containers/appHeader/appHeader";
import AppFooter from "containers/appFooter/appFooter";
import MainLayout from "containers/layout/mainLayout";
import BaseModalContainer from "containers/modalContainers/baseModalContainer";
import React, {useEffect, useState} from "react";
import {RootState, useAppDispatch} from "redux/store";
import {getUserLocalStorageData, removeUserLocalStorageData} from "utils/storageActions";
import AppLoader from "containers/appLoader/appLoader";
import GdprConsent from "components/gdprConsent/gdprConsent";
import BaseToastContainer from "containers/baseToastContainer/baseToastContainer";
import "react-toastify/dist/ReactToastify.css";
import {useSelector} from "react-redux";
import {getAuthenticationThunk} from "./redux/actions/authAction";

const App: React.FC = () => {
    const [isReady, setIsReady] = useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const initializeUserData = async () => {
            const token = getUserLocalStorageData();
            if (token) {
                if (new Date(token.expires) > new Date()) {
                    await dispatch(getAuthenticationThunk());
                } else {
                    removeUserLocalStorageData();
                }
            }
            setIsReady(true);
        };

        initializeUserData();
    }, []);

    return (
        <>
            <div className="app">
                <BaseToastContainer/>
                <AppLoader/>

                {isReady && (
                    <>
                        <BaseModalContainer/>
                        <AppHeader/>
                        <MainLayout/>
                        <AppFooter/>
                        <GdprConsent/>
                    </>
                )}
            </div>
        </>
    );
};

export default App;
