import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getUniversitiesData, getUniversitiesProgramData,} from "redux/actions/universityAction";
import {UniversityPreview, UniversityProgram} from "redux/models/universityModels";

export interface IUniversityState {
    universities: UniversityPreview[];
    universityPrograms: UniversityProgram[];
}

const initialState: IUniversityState = {
    universities: [],
    universityPrograms: [],
};

export const UniversityReducer = createSlice({
    name: "University",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(
            getUniversitiesData.fulfilled,
            (state: IUniversityState, action: PayloadAction<UniversityPreview[]>) => {
                state.universities = action.payload;
            }
        );
        builder.addCase(
            getUniversitiesProgramData.fulfilled,
            (state: IUniversityState, action: PayloadAction<UniversityProgram[]>) => {
                state.universityPrograms = action.payload;
            }
        );
    },
});

const { reducer } = UniversityReducer;
export const {} = UniversityReducer.actions;
export default reducer;
