import { FC } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getLanguages } from "utils/helperFunctions";
import { handleChangeLanguage } from "utils/helperFunctions";
import "./languageSelector.scss";

const languages = getLanguages();

interface LanguageSelectorProps {}

const LanguageSelector: FC<LanguageSelectorProps> = () => {
    const { i18n } = useTranslation();

    const updateLanguage = (_: any, event: any) => {
        handleChangeLanguage(event.target.text);
    };

    return (
        <Dropdown align={'end'} className="lang-dropdown" onSelect={updateLanguage}>
            <Dropdown.Toggle className="lang-dropdown__toggle-action">{i18n.resolvedLanguage}</Dropdown.Toggle>
            <Dropdown.Menu className="lang-dropdown__menu">
                {languages.map(mappedLanguage => {
                    return (
                        <Dropdown.Item key={mappedLanguage.value} eventKey={mappedLanguage.value} className="lang-dropdown__menu-item">
                            {mappedLanguage.name}
                        </Dropdown.Item>
                    );
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default LanguageSelector;
