import { StringResources } from "./languageResource";
import {
    EnrollmentStatusEnum,
    EnrolmentTypeEnum,
    HousingCapacityEnum,
    HousingSortByEnum,
    RoomateTypeEnum,
    SecondaryEducationEnum,
} from "utils/enums";

export const translationsEn = {
    [StringResources.modal.close]: "Close",
    [StringResources.modal.confirm]: "Confirm",

    [StringResources.login.subtitle]: "Find your home, away from home.",
    [StringResources.login.title]: "Welcome back!",
    [StringResources.login.formTitle]: "Sign in",
    [StringResources.login.formPasswordInputLabel]: "Password",
    [StringResources.login.formUsernameInputLabel]: "Username",
    [StringResources.login.formButtonSubmit]: "Sign in",
    [StringResources.login.forgotPassword]: "Forgot password? Send email.",
    [StringResources.login.formUsernameInputError]: "Username is required",
    [StringResources.login.formPasswordInputError]: "Password is required",

    [StringResources.resetPassword.subtitle]: "Find your home, away from home.",
    [StringResources.resetPassword.title]: "Reset your password",
    [StringResources.resetPassword.formTitle]: "Enter new password",
    [StringResources.resetPassword.formPasswordInputLabel]: "Password",
    [StringResources.resetPassword.formPasswordInputError]: "Password is required",
    [StringResources.resetPassword.formConfirmPasswordInputLabel]: "Confirm password",
    [StringResources.resetPassword.formConfirmPasswordInputError]: "Confirm password is required",
    [StringResources.resetPassword.saveButtonLabel]: "Save password",
    [StringResources.resetPassword.formConfirmPasswordMatchError]: "Passwords do not match",

    [StringResources.changePassword.subtitle]: "Find your home, away from home.",
    [StringResources.changePassword.title]: "Change your password",
    [StringResources.changePassword.formTitle]: "Change old password",
    [StringResources.changePassword.formOldPasswordInputLabel]: "Old password",
    [StringResources.changePassword.formOldPasswordInputError]: "Old password is required",
    [StringResources.changePassword.formPasswordInputLabel]: "New password",
    [StringResources.changePassword.formPasswordInputError]: "New password is required",
    [StringResources.changePassword.formConfirmPasswordInputLabel]: "Confirm new password",
    [StringResources.changePassword.formConfirmPasswordInputError]: "Confirm new password is required",
    [StringResources.changePassword.saveButtonLabel]: "Change password",
    [StringResources.changePassword.formConfirmPasswordMatchError]: "Passwords do not match",

    [StringResources.forgotPassword.subtitle]: "Find your home, away from home.",
    [StringResources.forgotPassword.title]: "Reset your password",
    [StringResources.forgotPassword.formTitle]: "Enter your email",
    [StringResources.forgotPassword.formEmailInputLabel]: "Email",
    [StringResources.forgotPassword.formEmailRequiredInputError]: "Email is required",
    [StringResources.forgotPassword.formEmailInputError]: "Invalid email address",
    [StringResources.forgotPassword.saveButtonLabel]: "Send email",

    [StringResources.createAccount.subtitle]: "Find your home, away from home.",
    [StringResources.createAccount.title]: "Welcome to",
    [StringResources.createAccount.formNameInputLabel]: "Name",
    [StringResources.createAccount.formLastNameInputLabel]: "Last Name",
    [StringResources.createAccount.formEmailInputLabel]: "Email Address",
    [StringResources.createAccount.formPhoneInputLabel]: "Phone",
    [StringResources.createAccount.formDateInputLabel]: "dd/mm/yyyy",
    [StringResources.createAccount.formUsernameInputLabel]: "Username",
    [StringResources.createAccount.formPasswordInputLabel]: "Password",
    [StringResources.createAccount.formConfirmPasswordInputLabel]: "Confirm",
    [StringResources.createAccount.formCitizenshipInputLabel]: "Citizenship",
    [StringResources.createAccount.formButtonSubmit]: "Continue",
    [StringResources.createAccount.formNameInputRequiredError]: "Name is required",
    [StringResources.createAccount.formLastNameInputRequiredError]: "Last name is required",
    [StringResources.createAccount.formEmailInputRequiredError]: "Email is required",
    [StringResources.createAccount.formEmailInputError]: "Invalid email address",
    [StringResources.createAccount.formPhoneInputRequiredError]: "Phone is required",
    [StringResources.createAccount.formDateInputRequiredError]: "Date of birth is required",
    [StringResources.createAccount.formUsernameInputRequiredError]: "Username is required",
    [StringResources.createAccount.formPasswordInputRequiredError]: "Password is required",
    [StringResources.createAccount.formConfirmPasswordInputRequiredError]: "Confirm password is required",
    [StringResources.createAccount.formConfirmPasswordMatchError]: "Passwords do not match",
    [StringResources.createAccount.formCitizenshipInputRequiredError]: "Citizenship is required",
    [StringResources.createAccount.formTerms]: "I confirm that I have read and understood the",
    [StringResources.createAccount.formTermsLink]: "Terms and Conditions",
    [StringResources.createAccount.formPrivacy]: "I confirm that I have read and understood the",
    [StringResources.createAccount.formPrivacyLink]: "Privacy Policy",
    [StringResources.createAccount.formCollegeInputRequiredError]: "College is required",
    [StringResources.createAccount.formEnrollmentTypeInputRequiredError]: "Enrollment type is required",
    [StringResources.createAccount.formSecondaryEducationInputRequiredError]: "Secondary education is required",
    [StringResources.createAccount.formCollegeProgramInputRequiredError]: "College program is required",
    [StringResources.createAccount.formEnrollmentStatusInputRequiredError]: "Enrollment status is required",
    [StringResources.createAccount.formTermsAgreedInputRequiredError]: "You must agreed with Terms and conditions",
    [StringResources.createAccount.formPrivacyAgreedInputRequiredError]: "You must agreed with Privacy Policy",
    [StringResources.createAccount.formCollegeInputPlaceholder]: "University",
    [StringResources.createAccount.formEnrollmentTypeInputPlaceholder]: "Enrollment type",
    [StringResources.createAccount.formSecondaryEducationInputPlaceholder]: "Secondary education finished",
    [StringResources.createAccount.formCollegeProgramInputPlaceholder]: "University program",
    [StringResources.createAccount.formEnrollmentStatusInputPlaceholder]: "Enrollment status",
    [StringResources.createAccount.createAccountSuccess]: "Account created successfully",
    [StringResources.createAccount.createAccountError]: "Error creating account",

    [StringResources.header.navLinkHome]: "Home",
    [StringResources.header.navLinkHousing]: "Housing",
    [StringResources.header.navLinkAbout]: "About us",
    [StringResources.header.navLinkContact]: "Contact us",

    [StringResources.footer.text1]: "share capital",
    [StringResources.footer.text2]: "20.000,00 kn,",
    [StringResources.footer.text3]: "paid in full.",
    [StringResources.footer.primaryNavTitle]: "Navigation",
    [StringResources.footer.secondaryNavTitle]: "Get in Touch",
    [StringResources.footer.primaryNavLinkHome]: "Homepage",
    [StringResources.footer.primaryNavLinkHousing]: "Housing",
    [StringResources.footer.primaryNavLinkAbout]: "About us",
    [StringResources.footer.primaryNavLinkContact]: "Contact us",
    [StringResources.footer.bottomText]: "All right reserved (C) Edudom",

    [StringResources.settings.navLinkAccount]: "Account",
    [StringResources.settings.navLinkChangePassword]: "Change password",
    [StringResources.settings.navLinkLogout]: "Sign out",

    [StringResources.housing.heroTitle]: "Explore apartments",
    [StringResources.housing.heroSubitle]: "Consider your own unique needs",
    [StringResources.housing.heroText]: "Find you home today",
    [StringResources.housing.filtersTitle]: "Reservation details",
    [StringResources.housing.filterAction]: "Filter",
    [StringResources.housing.filterPriceRangeSlider]: "Price range (€*):",
    [StringResources.housing.moveInDate]: "Move-in Date",
    [StringResources.housing.moveOutDate]: "Move-out Date",
    [StringResources.housing.housingList.sortBy]: "Sort by:",

    [StringResources.housingDetails.heroTitlePart]: "Apartment",
    [StringResources.housingDetails.heroSubitle]: "One step closer to your",
    [StringResources.housingDetails.moveInDate]: "Move-in Date",
    [StringResources.housingDetails.moveOutDate]: "Move-out Date",
    [StringResources.housingDetails.title]: "Check your dates",
    [StringResources.housingDetails.action]: "Proceed",

    [StringResources.housingReservation.heroTitlePart]: "Apartment",
    [StringResources.housingReservation.heroTitle]: "Reservation",
    [StringResources.housingReservation.heroSubitle]: "One step the closer to your",
    [StringResources.housingReservation.moveInDate]: "Move-in Date",
    [StringResources.housingReservation.moveOutDate]: "Move-out Date",
    [StringResources.housingReservation.roommateOption]: "Roommate option:",
    [StringResources.housingReservation.haveRoommate]: "I have a Roommate",
    [StringResources.housingReservation.findRoommate]: "Find a Roommate",
    [StringResources.housingReservation.reserveBtn]: "Reserve now",
    [StringResources.housingReservation.checkboxLabel]: "I confirm that I have read and understood the",
    [StringResources.housingReservation.checkboxLink]: "Rent Agreement",
    [StringResources.housingReservation.backLink]: "Back",
    [StringResources.housingReservation.roommate]: "Roommate:",
    [StringResources.housingReservation.formNameInputLabel]: "First Name",
    [StringResources.housingReservation.formLastNameInputLabel]: "Last Name",
    [StringResources.housingReservation.formEmailInputLabel]: "Email Address",
    [StringResources.housingReservation.formPhoneInputLabel]: "Phone",
    [StringResources.housingReservation.roomAvailability]: "Room availability",
    [StringResources.housingReservation.dontWantRoomate]: "I don't want a Roommate",
    [StringResources.housingReservation.reservationError]: "Error while making reservation",
    [StringResources.housingReservation.reservationSuccess]: "Reservation successful",

    [StringResources.profile.sraContactTitle]: "SRA Contact information",
    [StringResources.profile.sraContactPhone]: "Phone",
    [StringResources.profile.sraContactEmail]: "E-mail",
    [StringResources.profile.praContactTitle]: "PRA Contact information",
    [StringResources.profile.praContactPhone]: "Phone",
    [StringResources.profile.praContactEmail]: "E-mail",
    [StringResources.profile.infoBarCheckInDate]: "Check in date",
    [StringResources.profile.infoBarCheckOutDate]: "Check out date",
    [StringResources.profile.infoBarInstitution]: "Institution",
    [StringResources.profile.infoBarProgram]: "Program",
    [StringResources.profile.tabResidency]: "Residency",
    [StringResources.profile.tabHousing]: "Housing",
    [StringResources.profile.tabEducation]: "Education",
    [StringResources.profile.tabPayment]: "Payment",
    [StringResources.profile.tabAccount]: "Account",
    [StringResources.profile.tabGeneral]: "General",
    [StringResources.profile.tabStudent]: "Student",
    [StringResources.profile.accountTabTitle]: "Personal information",
    [StringResources.profile.accountFormUsernameInputLabel]: "Username",
    [StringResources.profile.accountFormFirstNameInputLabel]: "First Name",
    [StringResources.profile.accountFormLastNameInputLabel]: "Last Name",
    [StringResources.profile.accountFormEmailInputLabel]: "Email",
    [StringResources.profile.accountFormFirstNameInputPlaceholder]: "Your First Name",
    [StringResources.profile.accountFormLastNameInputPlaceholder]: "Your Last Name",
    [StringResources.profile.accountFormUsernameInputPlaceholder]: "Your Username",
    [StringResources.profile.accountFormEmailInputPlaceholder]: "Your Email",
    [StringResources.profile.accountFormNameInputRequiredError]: "First name is required",
    [StringResources.profile.accountFormLastNameInputRequiredError]: "Last name is required",
    [StringResources.profile.accountFormEmailInputRequiredError]: "Email is required",
    [StringResources.profile.accountFormEmailInputError]: "Invalid email address",
    [StringResources.profile.accountFormUsernameInputRequiredError]: "Username is required",
    [StringResources.profile.accountFormPhoneInputRequiredError]: "Phone number is required",
    [StringResources.profile.accountFormPhoneInputLabel]: "Phone number",
    [StringResources.profile.accountFormPhoneInputPlaceholder]: "Your phone number",
    [StringResources.profile.accountFormPhoneInputError]: "Invalid phone number",
    [StringResources.profile.accountTermsConditionsCaption]: "Terms and conditions",
    [StringResources.profile.accountPrivacyPolicyCaption]: "Privacy policy",
    [StringResources.profile.accountDeactivateAccountCaption]: "Deactivate account",
    [StringResources.profile.accountSaveCaption]: "Save",
    [StringResources.profile.accountUploadCaption]: "Edit",
    [StringResources.profile.paymentTabTitle]: "Payment informations",
    [StringResources.profile.paymentTabIBAN]: "IBAN",
    [StringResources.profile.paymentTabReferenceNumber]: "Reference number",
    [StringResources.profile.paymentTabAccommodation]: "Accommodation",
    [StringResources.profile.paymentTabDeposit]: "Deposit",
    [StringResources.profile.paymentTabAmmount]: "Ammount",
    [StringResources.profile.paymentTabRent]: "Rent",
    [StringResources.profile.paymentTabUtilities]: "Utilities",
    [StringResources.profile.paymentTabUtilitiesSeperately]: "Utilities are paid separately",
    [StringResources.profile.paymentTabAgencyFees]: "Agency fee",
    [StringResources.profile.paymentTabAgencyFeeLandlord]: "Agency fee + VAT",
    [StringResources.profile.paymentTabDepositMessage]:
        "Send proof of payment to students@edudom.eu to confirm your reservation.",
    [StringResources.profile.paymentTabTotal]: "Total",
    [StringResources.profile.paymentTabDownloadPdf]: "Download PDF",
    [StringResources.profile.housingTabTitle]: "Properties",
    [StringResources.profile.housingTabRoommates]: "Roommate",
    [StringResources.profile.housingTabFavoritesTitle]: "Favorites list",
    [StringResources.profile.housingTabFavoritesFallbackMsg]: "No favorites",
    [StringResources.profile.housingTabFavoritesAvailability]: "Availability",
    [StringResources.profile.educationTabTableColumnDocument]: "Document",
    [StringResources.profile.educationTabTableColumnFormat]: "Format",
    [StringResources.profile.educationTabTableColumnSpecification]: "Specification",
    [StringResources.profile.educationTabTableColumnPickup]: "Pick-up",

    [StringResources.home.heroTitle]: "find your home,",
    [StringResources.home.heroSubitle]: "away from home...",
    [StringResources.home.heroActionLabel]: "Read more",
    [StringResources.home.contentTitle]: "Explore Zagreb",
    [StringResources.home.contentSubtitle]: "Find your way around Croatias capital",
    [StringResources.home.contentBlockTitle1]: "Zrinjevac",
    [StringResources.home.contentBlockTitle2]: "Maksimir",
    [StringResources.home.contentBlockTitle3]: "Jarun",
    [StringResources.home.contentBlockTitle4]: "Bundek",
    [StringResources.home.contentBlockText1]:
        "Welcome to Zrinjevac, a hidden gem in Zagreb perfect for young explorers! Immerse yourself in the park's charming pathways, beautiful flowerbeds, and vibrant music pavilion. Unwind, listen to live music, and enjoy the company of fellow students.",
    [StringResources.home.contentBlockText2]:
        "Hey, adventure seekers! Maksimir Park is your dream escape in Zagreb!  Experience nature's wonderland with its lush landscapes, serene lakes, and diverse wildlife. Grab your friends, have a picnic, and create unforgettable moments amidst nature's embrace.",
    [StringResources.home.contentBlockText3]:
        "Jarun Lake is Croatia's ultimate playground for young adventurers! Embrace the sun, challenge your water sports skills, and take part in the vibrant nightlife. With its sandy shores and endless excitement, Jarun guarantees the best of both worlds.",
    [StringResources.home.contentBlockText4]:
        "Bundek Park in Zagreb is your go-to spot for thrilling outdoor fun! Lounge in the picturesque lakeside setting, fire up a BBQ at the scenic picnic areas, and join the vibrant crowd at events.",
    [StringResources.home.eventsTitle]: "Events",
    [StringResources.home.partnersTitle]: "Partners",
    [StringResources.home.partnersText]:
        "Through our partner network, we created a community that facilitates student needs by collaborating with our partners and incorporating students’ feedback.",

    [StringResources.contact.title]: "We'd love to hear from you",
    [StringResources.contact.subtitle]:
        "Whether you want to ask us a question or get assistance in dealing with obstacles, you are more than welcome to contact us.",
    [StringResources.contact.formTitle]: "Get in touch",
    [StringResources.contact.formSubtitle]: "Feel free to fill out the form, and we will get in touch with you.",
    [StringResources.contact.formNameInput]: "Your name",
    [StringResources.contact.formEmailInput]: "Your email address",
    [StringResources.contact.formSubjectInput]: "Subject",
    [StringResources.contact.formMessageInput]: "Your message",
    [StringResources.contact.formPhoneInput]: "Your phone number",
    [StringResources.contact.formNameInputError]: "Name is required",
    [StringResources.contact.formEmailInputError]: "Invalid email address",
    [StringResources.contact.formEmailRequiredInputError]: "Email is required",
    [StringResources.contact.formSubjectInputError]: "Subject is required",
    [StringResources.contact.formPhoneInputError]: "Phone is required",
    [StringResources.contact.formMessageInputError]: "Message is required",
    [StringResources.contact.formButtonSubmit]: "Submit",
    [StringResources.contact.formMessage]: "We'll get back to you in 1-2 business days.",
    [StringResources.contact.applyForHousingTitle]: "Apply for housing",
    [StringResources.contact.housingRentBodyText]: "Fill out the form at the following link, and we will get in touch with you,",
    [StringResources.contact.housingRentBodyText2]: "or email us at students@edudom.eu",
    [StringResources.contact.rentPropertyTitle]:"Rent a property",


    [StringResources.rent.title]: "Ugovor",
    [StringResources.rent.span]: "o najmu",

    [StringResources.terms.title]: "Terms ",
    [StringResources.terms.span]: "and",
    [StringResources.terms.titlePart2]: " conditions",
    [StringResources.terms.scopeTitle]: "SCOPE OF APPLICATION",
    [StringResources.terms.scopeText]:
        "These Terms of use contain rights and duties which you fully accept by activating the account on EDUDOM. Registration of your profile implies that you have read and understood them and that you accepted to be obliged by them.",
    [StringResources.terms.definitionsTitle]: "DEFINITIONS",
    [StringResources.terms.definitionsText]:
        "For better understanding of these Terms of use, the following definitions will have the following meaning:",
    [StringResources.terms.edudomTitle]: "Definition",
    [StringResources.terms.edudomText]: "''EDUDOM''",
    [StringResources.terms.meaningTitle]: "Meaning",
    [StringResources.terms.meaningText]:
        "means platform/search engine on web domain educatum-domum.com, owned by the limited liability company Edudom d.o.o. za usluge, having the registered seat in Zagreb (Republic of Croatia), registered with the Court Registry of the Commercial Court in Zagreb under the registration number 081457340;",
    [StringResources.terms.termsOfUseTitle]: "''Terms of Use''",
    [StringResources.terms.termsOfUseTitleText]:
        "means this legal act representing the obligatory contractual agreement between EDUDOM and User. By accepting these Terms of use, the User has accepted the terms and conditions contained herein. These Terms of use are ruled by the substantive laws of the Republic of Croatia. All rights and obligations which are not directly defined by these Terms of use are regulated by the Croatian Act on Apartment Lease, Croatian Obligations Act and other provisions of applicable laws of the Republic of Croatia.;",
    [StringResources.terms.userTitle]: "''User(s)''",
    [StringResources.terms.userTitleText]:
        "means user having the registered profile on EDUDOM; a foreign student at student exchange/stay in the Republic of Croatia",
    [StringResources.terms.serviceTitle]: "''Service''",
    [StringResources.terms.serviceTitleText]:
        "means service offered and provided within registration period by EUDOM to User on EDUDOM platform and on site",

    [StringResources.terms.servicesAndCompensationTitle]: "SERVICES AND COMPENSATION",
    [StringResources.terms.servicesAndCompensationText]: "EDUDOM offers to its Users the Services as it follows:",
    [StringResources.terms.serviceItemA]:
        "Service of providing support in finding the housing during the stay in the Republic of Croatia, whereas the User can choose and make a reservation and deposit between the housing options offered at the EDUDOM platform",
    [StringResources.terms.serviceItemB]:
        "Service of providing the support - logistical and operational guidance on site - signing the rent contract with the owner, obtaining the personal identification number, registration of residential address in the Republic of Croatia, taking over the rented property",
    [StringResources.terms.serviceItemC]:
        "Service of providing the logistical and operational support with the documentation necessary to obtain for the proper registration at the faculty",
    [StringResources.terms.serviceItemD]:
        "Service of providing other relevant information for stay in the Republic of Croatia",
    [StringResources.terms.servicesChargingText]:
        "The Services under a) and b) are charged by EUDOM, while EUDOM provides the Services under c) and d) pro bono for its registered Users who already have been using the Services under a) and b).",
    [StringResources.terms.compensationText]:
        "The compensation for the Services under a) and b) will be paid in a way that the User will be making the payments of the designated rent for the chosen housing. The amount of designated rent includes the rent increased for the additional fee for EUDOM’s Service. By the payment of the amount of the designated rent, the User settles both, monthly rent and monthly fee for the Service designated hereinabove under a) and b).",
    [StringResources.terms.paymentProcedureText]:
        "The amount of designated rent will be paid directly to the account of EUDOM, while EUDOM undertakes the full liability of proceeding the payment to the property owner.",
    [StringResources.terms.additionalCostsText]:
        "The amount of designated rent does not include any costs which are not explicitly stated hereinabove under a) and b), including, but not limited to the following costs: any damages that either EUDOM, or property owner might incur due to the User’s activities and/or omissions; costs of court/administrative fees, public notary costs, costs of sworn court’s interpreters, costs of moving in the chosen property etc.",

    [StringResources.terms.activationTitle]: "ACTIVATION OF THE ACCOUNT",
    [StringResources.terms.ageRequirement]:
        "By registering the account the User warrants to be 18 years of age or older.",
    [StringResources.terms.minorRegistration]:
        "The User having less than 18 years will have the technical possibility of the registration. However, after the registration the User will be contacted by EDUDOM with the purpose of the coordination of the conclusion of the agreements and/or obtaining the documents from the User's legal representatives. In case where the student would not deliver the needed agreements and/or documents certified by the legal representatives, EDUDOM will annul the registration and erase the User's personal data.",
    [StringResources.terms.registrationProcedure]:
        "In order to register, the User will issue a username and password whereas the password will be known only to her/him. The registration is completed by a proof of User’s existence which is usually done by email confirmation.",
    [StringResources.terms.dataResponsibility]:
        "User undertakes the full liability for accuracy and lawfulness of all data published on User’s profile at EDUDOM.",
    [StringResources.terms.privacyPolicy]:
        "EDUDOM has developed and implemented the Privacy Policy which the registered Users need to read and accept before the registration and which are available here. Privacy Policy defines in more detail the rights and obligations of the Users' data contained in Users' profile.",
    [StringResources.terms.activationDuration]:
        "Upon the activation of the User's profile, the profile will be active on EDUDOM for an indefinite period of time.",
    [StringResources.terms.inactivityProcedure]:
        "EDUDOM takes account of the protection of data protection principles and it will, in case that the User has in course of the entire previous year been inactive, delete such profile. In such a case, EDUDOM reserves the right to send a warning email to the User thereof, in order to give to the User the opportunity to undertake the activities on its User's profile and to avoid the deletion of the profile.",
    [StringResources.terms.accountDeletion]:
        "The User can delete the User's profile on its own, either via control options on its User's profile, or by sending an email to EDUDOM.",
    [StringResources.terms.postDeletionProcedure]:
        "After deleting the User's profile, EDUDOM will keep the contact data for the purposes of sending the newsletter, unless the User object thereto or deregisters him from the newsletter list.",
    [StringResources.terms.accountRecreation]:
        "Deletion of the User's profile, either by EDUDOM, or by the User, does not affect the possibility to create the new User's profile, by the same User, in the future.",
    [StringResources.terms.visitorAccess]:
        "Visitors who are not subscribed will have limited approach to the EDUDOMs content to the extent necessary to get the general impression on content and features of the service.",

    [StringResources.terms.liabilityTitle]: "LIABILITY; COMPENSATION FOR DAMAGES",
    [StringResources.terms.liabilityText]: "EDUDOM is liable for:",
    [StringResources.terms.liabilityListA]:
        "permanently training and educating the employees and business partners for providing high-level Service in accordance to these Terms of use and professional standards;",
    [StringResources.terms.liabilityListB]:
        "providing the Service within defined deadlines and according to the step-plan indicated on the platform/set within reservation procedure;",
    [StringResources.terms.liabilityListC]: "equally treating all visitors and/or registered Users",
    [StringResources.terms.liabilityText2]:
        "EDUDOM is liable only for those misconducts among those listed hereinabove if they are committed by intent or by gross negligence. EDUDOM will not be held liable for misconducts resulting from ordinary negligence.",
    [StringResources.terms.nonLiabilityText3]: "EDUDOM is not liable for:",
    [StringResources.terms.nonLiabilityListA]: "finding the matching housing for the User",
    [StringResources.terms.nonLiabilityListB]:
        "any inaccurate data published on User’s profile or in the property presentation",
    [StringResources.terms.nonLiabilityListC]: "legal representation of the Users",
    [StringResources.terms.nonLiabilityListD]:
        "potential invalid and unlawful performance of obligations of the User toward the property owner and vice versa",
    [StringResources.terms.nonLiabilityListE]:
        "any kind of unethical and/or unprofessional behavior of the User or property owner connected with EDUDOM platform in any manner",
    [StringResources.terms.nonLiabilityListF]:
        "User’s or property owner’s non-compliance with any kind of regulatory requirements",
    [StringResources.terms.nonLiabilityListG]:
        "temporary unavailability of the Service due to technical maintenance, installation(s)/modification work on EDUDOM platform",
    [StringResources.terms.liabilityText3]:
        "The User in any case undertakes full liability for entering into any kind of contractual or other legal relation with property owners and/or performing any of the rights and/or obligations motivated by activities on EDUDOM platform.",
    [StringResources.terms.liabilityText4]:
        "Notwithstanding the fact that the Users will be exclusively held liable for their own activities connected anyhow with the EDUDOM, all the Users are encouraged to report to EDUDOM any bad experience anyhow connected to using the EDUDOM platform. EDUDOM reserves the right to further examine such reports and to bring independent decision whether to continue the cooperation with the reported person, taking into account how reported occasions might affect the quality of the Service and/or reputation of EDUDOM.",
    [StringResources.terms.liabilityText5]:
        "Would any third party, including but not limiting to regulatory bodies, find EDUDOM liable for any circumstances listed in this Article as falling out of scope of EDUDOM’s liability under these Terms of use,  the User will, upon first EDUDOM’s call, undertake the liability for all monetary and non-monetary claims that any third party had directed toward EDUDOM. In case where EDUDOM would be obliged, for any reason, to make any payments/compensations in kind or any other kind of remuneration for the circumstances falling out of the scope of EDUDOM’s liability, the User in matter will remunerate EDUDOM for the paid amount increased for interest rate starting as of the day of the payment by EDUDOM.",

    [StringResources.terms.terminationTitle]: "UNILATERAL TERMINATION",
    [StringResources.terms.terminationText1]:
        "The Service accepted remotely (online) can be unilaterally terminated by the User without designating the reason for the termination. The unilateral termination can be declared by the User within 14 days from ordering the Service i.e. the User is entitled to unilaterally cancel the reservation for the housing made on EDUDOM platform.",
    [StringResources.terms.terminationText2]:
        "The notice on the unilateral termination of the Service needs to be sent to EDUDOM via email ed@edudom.eu, unambiguously informing EDUDOM that the Service is unilaterally terminated by the User.",
    [StringResources.terms.terminationText3]:
        "In case where the User unilaterally terminates the Service, EDUDOM will pay back the compensation and deposit to such User. EDUDOM is due to make the payment via bank transfer and only in the amount of the paid compensation and deposit. User bears all costs related to the international bank transfers, conversion costs etc. EDUDOM will pay the compensation back to the User within 14 days as of the day when EDUDOM had received the termination notice from the Buyer.",
    [StringResources.terms.terminationText4]:
        "In case where the User would, within 14 days as of making a reservation online, sign the rent contract on site, the User loses the right to unilateral termination contract under this Article.",
    [StringResources.terms.customerServiceTitle]: "CUSTOMER SERVICE; INFORMATION DELIVERY; CONSUMERS’ OBJECTION",
    [StringResources.terms.customerServiceText]:
        "EDUDOM is available to the Users on the following contact data: ed@edudom.eu In case where the User, for any reason, would not be content with the provided Service, it can file a consumer’s objection on the email address stated hereinabove. EDUDOM will provide the answer to User’s inquiries as soon as possible, but not later than 15 days as of the day of receipt. All communication, information, updates etc. will be sent to contact data that the User had provided in its User’s profile. In case of change of any contact data, the User is due to make necessary amendments in the User's profile without delays.",
    [StringResources.terms.disputeResolutionTitle]: "DISPUTE RESOLUTION",
    [StringResources.terms.disputeResolutionText]:
        "EDUDOM and the User will, in good will and in amicable manner try to solve any disputes arising out or in connection with these Terms of use and/or performance of rights and duties arising out or in connection with the Service and/or, including but not limited to (in)validity, breach or termination or any legal consequences arising thereof. If the User is resident of an EU state, Liechtenstein, Norway or Iceland, the User can start the proceedings for the amicable dispute resolution on the internet platform https://ec.europa.eu/consumers/odr/main/?event=main.home2.show. In case where resolution of the dispute is not possible according to one of the previous paragraphs of this Article, EDUDOM and the User will resolve the dispute in front of the competent court in Croatia, Zagreb.",
    [StringResources.terms.miscellaneousTitle]: "MISCELLANEOUS",
    [StringResources.terms.miscellaneousText]:
        "These Terms of use are regularly updated with the purpose of further clarifications if needed and/or in order to adapt the Terms of use to possible amendments and improvements of service. All updates will be created and published in the same manner as these Terms of use. All Users will be specifically informed of any amendment of Terms of use either on the first next occasion when they visit EDUDOM, or via email. Each amendment of these Terms of use will designate the date as of which the amendments will be applicable.",
    [StringResources.terms.termsPublishDate]: "These Terms of use are created and published on 31. March 2023",

    [StringResources.privacy.privacyTitle]: "Privacy policy",
    [StringResources.privacy.privacyTitle2]: "PRIVACY PROTECTION",
    [StringResources.privacy.privacyText1]:
        "EDUDOM collects directly from the User the personal data processed within the business cooperation between them, i.e. EDUDOM disposes with the User’s personal data given by the User himself/herself for the purposes of each specific business cooperation.",
    [StringResources.privacy.privacyText2]:
        "EDUDOM processes certain User’s personal data on basis of statutory obligation (e.g. performing the duty of keeping the agency notes or complying with the statutory duties related to anti money laundering and terrorism financing)",
    [StringResources.privacy.privacyText3]:
        "EDUDOM processes certain User’s personal data in order to perform its agency service (e.g. name, surname, address, PIN, contact data)",
    [StringResources.privacy.privacyText4]:
        "EDUDOM processes certain User’s personal data on the legitimate interest basis. EDUDOM has the legitimate interest to send to the User the informative messages with the content related to the subject of the cooperation between them, as well as to send the messages with the purpose of offering the services which would be the same or similar to those which are subject of the cooperation between them, unless the User files the objection to such processing of his contact data.",
    [StringResources.privacy.privacyText5]:
        "EDUDOM cooperates with the educational institution in which the User is registered, with the purpose of providing the complete service of integration of the User in the Republic of Croatia. Therefore, EDUDOM proceeds to the educational institution the personal data of the student such as name and surname, status of reservation and the fact whether the student has concluded the lease agreement with the purpose of providing the student with the support while registering its residence address in Croatia, unless the User files the objection to such processing of his data.",
    [StringResources.privacy.privacyText6]:
        "In cases when for the realization of the transaction it would be necessary to draft a contract and/or other type of document or undertaking of other actions by the lawyer, personal data necessary for drafting such documents i.e. for undertaking of such activities will be forwarded to the lawyer.",
    [StringResources.privacy.privacyText7]:
        "EDUDOM delivers the Client’s personal data to the external bookkeeping service for the purpose of complying with the duties arising out of the Accounting Act and out of other bookkeeping regulations, with which bookkeeping service EDUDOM has entered into the data processing agreement by which they regulate data protection issues.",
    [StringResources.privacy.privacyText8]:
        "EDUDOM does not transfer the User’s personal data to other countries or international organizations, i.e. it does not transfer the User’s personal data outside the territory of the Republic of Croatia.",
    [StringResources.privacy.privacyText9]:
        "EDUDOM will keep the User’s personal data in course of business cooperation with them, after which the data will be erased, unless there is a legal obligation of keeping the certain data category for longer period of time, or if certain data category is necessary for realization of rights in the courts, or if the User gave its consent for processing the certain data category for specified purpose.",
    [StringResources.privacy.privacyText10]:
        "User can at any time request from EDUDOM the access to the User’s personal data, as well as to request from EDUDOM the rectification or erasure of the personal data, restriction of processing, right to file the object to processing.",
    [StringResources.privacy.privacyText11]: "",

    [StringResources.privacy.customerServiceTitle]: "CUSTOMER SERVICE; INFORMATION DELIVERY; CONSUMERS’ OBJECTION",
    [StringResources.privacy.customerServiceText1]:
        "EDUDOM is available to the Users on the following contact data: ed@edudom.eu",
    [StringResources.privacy.customerServiceText2]:
        "In case where the User, for any reason, would not be content with the provided Service, it can file a consumer’s objection on the email address stated hereinabove.",
    [StringResources.privacy.customerServiceText3]:
        "EDUDOM will provide the answer to User’s inquiries as soon as possible, but not later than 15 days as of the day of receipt.",
    [StringResources.privacy.customerServiceText4]:
        "All communication, information, updates etc. will be sent to contact data that the User had provided in the intermediation contract. In case of change of any contact data, the User is due to inform EDUDOM thereof.",

    [StringResources.about.title]: "We are",
    [StringResources.about.subtitle]:
        "Having studied in Zagreb, we faced many challenges. This platform is a guide to connect you with the community that will support your integration. Join us on this exciting adventure and explore living in Zagreb.",
    [StringResources.about.visionTitlePart1]: "Our",
    [StringResources.about.visionTitlePart2]: "Mission & Vision",
    [StringResources.about.visionText]:
        "We strive to become the bridge between students and their challenges to make their integration smoother in their new home. As a team, we do this by providing students with housing options that fit their needs.",
    [StringResources.about.studentsTitle]: "Students relations department",
    [StringResources.about.studentsText]:
        "The student relations department is your point of contact for all matters related to integrating into student life in Zagreb. Each student is appointed their Student Relations Associate (SRA) that takes you through your stay in Zagreb.",
    [StringResources.about.partnersTitle]: "Partners relations department",
    [StringResources.about.partnersText]:
        "The partner relations department is here to build relationships with partnering institutions that facilitate student life in Zagreb. Our Partner Relations Associates (PRA) communicate directly with partnering institutions and service providers to ensure a smooth process.",
    [StringResources.about.person1Position]: "CEO & Founder",
    [StringResources.about.person2Position]: "Operations Manager",
    [StringResources.about.person3Position]: "Partner Relations Associate",

    [StringResources.gdprConsent.text]:
        "By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.",
    [StringResources.gdprConsent.acceptAction]: "Accept All Cookies",
    [StringResources.gdprConsent.settingsAction]: "Cookie Settings",
    [StringResources.gdprConsent.necessaryCookies]: "Strictly Necessary",

    [StringResources.enums.roomateTypeEnum[RoomateTypeEnum.Student]]: "Student",
    [StringResources.enums.roomateTypeEnum[RoomateTypeEnum.NotAStudent]]: "Not a student",
    [StringResources.enums.housingCapacity[HousingCapacityEnum.Single]]: "Single",
    [StringResources.enums.housingCapacity[HousingCapacityEnum.Double]]: "Double",
    [StringResources.enums.housingCapacity[HousingCapacityEnum.Triple]]: "Triple",

    [StringResources.enums.enrolmentType[EnrolmentTypeEnum.StateExam]]: "State exam",
    [StringResources.enums.enrolmentType[EnrolmentTypeEnum.University]]: "University",
    [StringResources.enums.secondaryEducation[SecondaryEducationEnum.Croatia]]: "Croatia",
    [StringResources.enums.secondaryEducation[SecondaryEducationEnum.EU]]: "EU",
    [StringResources.enums.secondaryEducation[SecondaryEducationEnum.NonEU]]: "Non EU",
    [StringResources.enums.enrollmentStatus[EnrollmentStatusEnum.Exchange]]: "Exchange",
    [StringResources.enums.enrollmentStatus[EnrollmentStatusEnum.LongTerm]]: "Long term",
    [StringResources.enums.enrollmentStatus[EnrollmentStatusEnum.Transfer]]: "Transfer",

    [StringResources.enums.housingSort[HousingSortByEnum.Price]]: "Price",
    [StringResources.enums.housingSort[HousingSortByEnum.DistanceToUni]]: "Distance to UNI",
    [StringResources.enums.housingSort[HousingSortByEnum.Single]]: "Single",
    [StringResources.enums.housingSort[HousingSortByEnum.Double]]: "Double",
    [StringResources.enums.housingSort[HousingSortByEnum.Triple]]: "Triple",
};
