import axios from "api/axiosApiClient";
import { PayfacAxiosRequestConfig } from "api/requestModels";
import { getAuthorizationHeader } from "utils/storageActions";

export const executeAxiosRequest = async (config: PayfacAxiosRequestConfig) => {
    try {
        let requestConfig: PayfacAxiosRequestConfig = {
            ...config,
            url: axios.defaults.baseURL! + config.url,
            headers: {
                ...config.headers,
                ...getAuthorizationHeader(),
            },
        };

        const result = await axios(requestConfig);
        return { data: result.data };
    } catch (error: any) {
        return Promise.reject(error);
    }
};
