import { FC } from "react";
import { IconSVGProps } from "./types";
import InstagramIcon from "./instagramIcon";
import FacebookIcon from "./facebookIcon";
import TwitterIcon from "./twitterIcon";
import LinkedInIcon from "./linkedinIcon";
import UserIcon from "./userIcon";
import SettingsIcon from "./settingsIcon";
import BellIcon from "./bellIcon";
import LockIcon from "./lockIcon";
import LogoutIcon from "./logoutIcon";
import HamburgerMenuIcon from "./hamburgerMenuIcon";
import CancelIcon from "./cancelIcon";
import CheckIcon from "./checkIcon";
import ListIcon from "./listIcon";
import BedIcon from "./bedIcon";
import BathroomIcon from "./bathroomIcon";
import KitchenIcon from "./kitchenIcon";
import SquareMetersIcon from "./squareMetersIcon";
import LocationIcon from "./locationIcon";
import ImageIcon from "./imageIcon";
import HeartIcon from "./heartIcon";
import PlayIcon from "./playIcon";
import ChevronLeftIcon from "./chevronLeftIcon";
import ChevronRightIcon from "./chevronRightIcon";
import LoginIcon from "./loginIcon";
import SortIcon from "./sortIcon";
import CardIcon from "./cardIcon";
import ArrowRightIcon from "./arrowRightIcon";
import CancelCircleIcon from "./cancelCircleIcon";
import CheckCircleIcon from "./checkCircleIcon";
import WarningIcon from "./warningIcon";
import AboutBackground from "./aboutBackground";

const Icons: Record<string, FC<IconSVGProps>> = {
    InstagramIcon: InstagramIcon,
    FacebookIcon: FacebookIcon,
    TwitterIcon: TwitterIcon,
    LinkedInIcon: LinkedInIcon,
    UserIcon: UserIcon,
    SettingsIcon: SettingsIcon,
    BellIcon: BellIcon,
    LockIcon: LockIcon,
    LogoutIcon: LogoutIcon,
    HamburgerMenuIcon: HamburgerMenuIcon,
    CancelIcon: CancelIcon,
    CheckIcon: CheckIcon,
    ListIcon: ListIcon,
    BedIcon: BedIcon,
    BathroomIcon: BathroomIcon,
    KitchenIcon: KitchenIcon,
    SquareMetersIcon: SquareMetersIcon,
    LocationIcon: LocationIcon,
    ImageIcon: ImageIcon,
    HeartIcon: HeartIcon,
    PlayIcon: PlayIcon,
    ChevronLeftIcon: ChevronLeftIcon,
    ChevronRightIcon: ChevronRightIcon,
    LoginIcon: LoginIcon,
    SortIcon: SortIcon,
    CardIcon: CardIcon,
    ArrowRightIcon: ArrowRightIcon,
    CancelCircleIcon: CancelCircleIcon,
    CheckCircleIcon: CheckCircleIcon,
    WarningIcon: WarningIcon,
    AboutBackground: AboutBackground
};

export {
    InstagramIcon,
    FacebookIcon,
    TwitterIcon,
    LinkedInIcon,
    UserIcon,
    SettingsIcon,
    BellIcon,
    LockIcon,
    LogoutIcon,
    HamburgerMenuIcon,
    CancelIcon,
    CheckIcon,
    ListIcon,
    BedIcon,
    BathroomIcon,
    KitchenIcon,
    SquareMetersIcon,
    LocationIcon,
    ImageIcon,
    HeartIcon,
    PlayIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    LoginIcon,
    SortIcon,
    CardIcon,
    ArrowRightIcon,
    CancelCircleIcon,
    CheckCircleIcon,
    WarningIcon,
    AboutBackground
};

export default Icons;
