import i18n from "utils/language/languageClient";
import { ILookupResponse } from "./models";
import { SupportedLanguageEnum } from "./enums";

//TODO: helper function to to show error message from BE. This should be revisited and its usage should be deleted
export const noOp = (): void => void 0;

export const devConsoleLog = (param1?: any, param2?: any) => {
    //process.env.NODE_ENV != "production" && console.log(param1, param2);
};

export const devConsoleError = (param1?: any, param2?: any) => {
    process.env.NODE_ENV != "production" && console.error(param1, param2);
};

export const devConsoleAssert = (condition: boolean) => {
    process.env.NODE_ENV != "production" && console.assert(condition);
};

export const getLanguages = (): ILookupResponse<number>[] => {
    return [
        { value: 1, name: SupportedLanguageEnum[SupportedLanguageEnum.Eng] },
        { value: 2, name: SupportedLanguageEnum[SupportedLanguageEnum.Hr] },
    ];
};

export const handleChangeLanguage = (lng: string) => {
    localStorage.setItem("lng", lng);
    i18n.changeLanguage(lng);
};

export type ValueOf<T> = T[keyof T];

export const formatCurrency = (value: number) => {
    return value;
};

//This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
function calcCrow(lat1: number, lon1: number, lat2: number, lon2: number) {
    var R = 6371; // km
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    var lat1 = toRad(lat1);
    var lat2 = toRad(lat2);

    var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
}

// Converts numeric degrees to radians
function toRad(Value: number) {
    return (Value * Math.PI) / 180;
}

export function CalculateDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
    try {
        const degToRad = (degree: number) => (degree * Math.PI) / 180;
        const earthRadiusKm = 6371;

        const sinLat1 = Math.sin(degToRad(lat1));
        const sinLat2 = Math.sin(degToRad(lat2));
        const cosLat1 = Math.cos(degToRad(lat1));
        const cosLat2 = Math.cos(degToRad(lat2));
        const deltaLon = degToRad(lon2 - lon1);

        const a = sinLat1 * sinLat2 + cosLat1 * cosLat2 * Math.cos(deltaLon);
        const distance = Math.acos(a) * earthRadiusKm;

        return Math.round((distance + Number.EPSILON) * 100) / 100;
    } catch (e) {
        return 0;
    }
}
