import {IHousingData, ILandlordData, OwnedHousing} from "redux/models/housingModels";
import { IUserRoleEnum } from "utils/enums";
import {UniversityPreview, UniversityProgram} from "./universityModels";

export interface User {
    id: number,
    email: string,
    username: string,
    phoneNumber: string,
    firstName: string,
    lastName: string,
    profileImageUrl: string,
    roles: UserRole[],
    type: UserType,
}

export interface BasicUser extends User {
    // type: UserType.BASIC,
}

export interface LandlordUser extends User {
    // type: UserType.LANDLORD
    ownedProperties: OwnedHousing[];
}

export interface UniversityUser extends User {
    // type: UserType.UNIVERSITY
    students: UniversityStudents[];
}

export interface StudentUser extends User {
    type: UserType.STUDENT
    residencyDocuments: UserResidencyDocument[];
    universityPreview: UniversityPreview;
    universityDocuments: UniversityDocument[];
    universityProgram: UniversityProgram;
    rentedHousing: HousingPreview;
    housingFavorites: HousingPreview[];
}

interface UniversityStudents {
    createdAt: string;
    depositPaid: boolean;
    firstName: string;
    lastName: string;
    rentAgreement: boolean;
    enrolmentType?: string;
    tsApplication?: string;
    enrolmentStatus?: string;
    sraEmail?: string;
}

export interface UniversityDocument {
    universityDocumentId: number;
    name: string;
    format: string;
    pickup: string;
    submitted: boolean;
}

export interface UserResidencyDocument {
    userId:  number;
    residencyDocumentId: number;
    name: string;
    format: string;
    specification: string;
    pickup: string;
    category: ResidencyDocumentCategory;
    phase?: ResidencyDocumentPhase,
    submitted: boolean;
}

enum ResidencyDocumentCategory {
    TW= "TW",
    EEA= "EEA",
    CR= "CR",
}

enum ResidencyDocumentPhase {
    PRE_ENTRY= "PRE_ENTRY",
    POST_ENTRY= "POST_ENTRY",
}

export interface HousingPreview {
    id: number;
    name: string;
    address: string;
    availability: string;
    proposedRent: number;
    averageUtility?: number;
    capacity: string;
    size: number;
    latitude: number;
    longitude: number;
    tenants: TenantPreview[];
}

interface TenantPreview {
    id: number;
    firstName: string;
    lastName: string;
    profileImageUrl: string;
    moveOutDate: string;
    moveInDate: string;
}

export const isStudentUser = (user?: User): user is StudentUser => {
    return user?.type === UserType.STUDENT;
}

export const isLandlordUser = (user?: User): user is LandlordUser => {
    return user?.type === UserType.LANDLORD;
}

export const isUniversityUser = (user?: User): user is UniversityUser => {
    return user?.type === UserType.UNIVERSITY;
}

export enum UserType {
    BASIC = "BASIC",
    STUDENT = "STUDENT",
    LANDLORD = "LANDLORD",
    UNIVERSITY = "UNIVERSITY",
}

export enum UserRole {
    ADMIN = "ADMIN",
    USER = "USER"
}

export interface IUserState {
    // userId?: number;
    // role?: IUserRoleEnum;
    // username?: string;

    // email?: string;
    // password?: string;
    // name?: string;
    // phoneNumber?: string;
    // token?: string;
    // referencedBy?: any;
    // dateOfBirth?: number;
    // citizenship?: string;
    // department?: any;
    // position?: any;
    // isDeleted?: boolean;
    // tenant?: any;
    details?: User;
    studentDetails?: IStudentData;
    userData?: IUserDetailsResponse;
    studentHousing?: IStudentHousing;
    documents?: IDocument[];
    uniDocuments?: IUniDocument[];
    favorites?: IHousingData[];
    landlordProfile?: ILandlordProfileData;
    universityProfile?: IUniversityProfileData;
    userImageUrl?: string;
    notifications?: INotification[];
}

export interface IUserDetailsRequest {
    username: string;
}

export interface IUserDetailsResponse {
    userId?: number;
    role?: IUserRoleEnum;
    username?: string;

    email: string;
    password: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    token: string;
    referencedBy?: any;
    dateOfBirth: number;
    citizenship: string;
    department?: any;
    position?: any;
    isDeleted?: boolean;
    tenant?: any;
    studentDetails?: IStudentData;
}

export interface IStudentData {
    uid: number;
    roomateFriendly?: boolean;
    enrollmentType: string;
    enrollmentStatus: string;
    universityProgram: string;
    secondEducation: string;
    erasmus?: boolean;
    erasmusStartDate?: string;
    erasmusEndDate?: string;
    fineInformation?: string;
    fineAmount?: number;
    uniGeoLongitude?: string;
    uniGeoLatitude?: string;
    tsApplication?: any;
    rentAgreement?: any;
    depositPaid?: any;
    student?: any;
    uniName?: string;
}

export interface IStudentHousing {
    housingId: number;
    housingDescription: string;
    housingName?: string; // fali na be
    housingType: string;
    housingCapacity: string;
    housingSize: number;
    housingAddress: string;
    housingNeighbourhood: string;
    city?: string;
    country?: string;
    zip?: number;
    pricingCurrency?: string;
    yearOfConstruction: number;
    yearOAdaptation: number;
    energyCertificate?: string;
    floor: number;
    proposedRent: number;
    averageUtility: number;
    proposedDeposit: number;
    contactNumber: string;
    contactNumberAlternative?: string;
    minimumRentPeriod: number;
    rentDueDate?: number;
    availableFrom?: number;
    rentingSeason?: string;
    availability: string;
    postedOn: number;
    link?: string;
    landlord?: ILandlordData; // fali na BE
    praemail?: string;
    praname?: string;
    longitude: string;
    latitude: string;
    tenant?: Array<any>;
}

export interface IDocument {
    studentResDocsId: number;
    residencyDocument: {
        documentName: string;
        documentFormat: string;
        documentSpecification: string;
        documentPickup: string;
        documentType: string;
        residencyDocId: number;
    };
    docSubmitted: boolean;
}

export interface IUniDocument {
    uid: number;
    uniDoc: {
        uid: number;
        uniDoc: {
            uniDocId: number;
            documentName: string;
        };
        documentFormat: string;
        documentPickup: string;
    };
    docSubmitted: boolean;
}

export interface ITentat {
    housingTenantId?: number;
    moveInDate?: number;
    moveOutDate?: number;
    roommateFullName?: string;
    actualRent?: number;
    actualUtilities?: number;
    actualDeposit?: number;
    actualMinimumRentPeriod?: number;
    actualRentDueDate?: number;
    repair?: string;
    repairDate?: number;
    repairReportedDate?: number;
}

export interface IUpdateUserRequest {
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
}

export interface ILandlordProfileData {
    propertyList?: IStudentHousing[];
}

export interface IUniversityProfileData {
    preEntryDocuments?: IUniversityDocument[];
    uniStudnets?: any[];
}

export interface IUniversityDocument {
    registeredDate: string;
    name: string;
    surname: string;
    typeOfEnrollment: string;
    depositPayed: boolean;
    tsApplication: string;
    rentAgreement: boolean;
    status: string;
    SRA?: string;
}

export interface INotification {
    id: number;
    userId: number;
    text: string;
    action?: "enrollment" | "residency";
}
