interface ApiErrorResponse {
    error: ApiError;
}

interface ApiError {
    id: string,
    statusCode: number,
    timestamp: string,
    error: string, //ie. status code default text
    message?: string, //custom service message
    errors?: ApiValidationError[], // null except when message is "Validation failed"
}

interface ApiValidationError {
    field: string,
    message: string,
}

export const isApiServiceError = (serviceError: unknown): serviceError is ApiErrorResponse => {
    return (serviceError as ApiErrorResponse).error !== undefined;
}