import classNames from "classnames";
import { useEffect, useRef } from "react";
import { CheckIcon } from "components/icons";
import "./baseCheckbox.scss";

interface IBaseCheckboxProps {
    styleType?: "alpha" | "beta";
    id: string;
    labelEl?: any;
    label?: string;
    checked: boolean;
    name?: string;
    onChange?: (id: string, checked: boolean) => void;
    disabled?: boolean;
    invalid?: string | undefined | boolean;
}

const BaseCheckbox = ({ styleType = "alpha", id, labelEl, label, checked, onChange, name, disabled, invalid }: IBaseCheckboxProps) => {
    const checkRef = useRef<HTMLInputElement>(null);

    const onValueChange = () => {
        if (onChange) {
            onChange(id, !checked);
        }
    };

    return (
        <div className="base-checkbox__wrap">
            <label
                className={classNames(`base-checkbox base-checkbox--${styleType}`, {
                    "base-checkbox--disabled": disabled,
                })}
            >
                <div
                    className={classNames("base-checkbox__checkmark ", {
                        "base-checkbox__checkmark--checked": checked,
                    })}
                >
                    <div
                        className={classNames("checkbox-icon", {
                            "checkbox-icon--checked": checked,
                        })}
                    >
                        {checked && <CheckIcon />}
                    </div> 
                </div>
                {label && <BaseCheckboxLabel text={label} />}
                {labelEl && labelEl}
                <input
                    name={name}
                    className="base-checkbox__input"
                    type="checkbox"
                    ref={checkRef}
                    id={id}
                    checked={checked}
                    disabled={disabled}
                    onChange={onValueChange}
                />
            </label>
            {invalid && <p className="base-checkbox__error">{invalid}</p>}
        </div>
    );
};

interface BaseCheckboxLabelProps {
    text: string;
}

const BaseCheckboxLabel = ({ text }: BaseCheckboxLabelProps) => {
    if (!text) return <></>;
    return <span className="base-checkbox__text">{text}</span>;
};

export default BaseCheckbox;
