import { IconSVGProps } from "./types";

const BedIcon = ({ height = 37, width = 47, className }: IconSVGProps) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fillRule="evenodd" clipRule="evenodd" d="M46.8785 25.428L41.708 17.3088V2.37326L39.6032 0.285706H7.37001L5.26528 2.37326V17.3087L0.0947266 25.428H46.8785ZM37.4987 4.46163V14.8829C34.7862 14.187 30.1004 13.3288 23.4868 13.3288C16.8732 13.3288 12.1874 14.187 9.47492 14.8829V4.46163H37.4987ZM0 31.1717V26.9966H46.9725V31.1717H42.068V36.9211H37.8585V31.1717H9.11386V36.9211H4.90439V31.1717H0ZM23.4854 6.82039C20.6861 5.8808 18.0777 5.10944 17.0589 5.10944C14.4531 5.10944 12.3408 6.28014 12.3408 7.72377V11.3745C12.3408 11.3745 15.1239 10.6795 17.0368 10.4999C17.7416 10.4336 20.4879 10.364 23.4867 10.3048C26.4845 10.364 29.2295 10.4336 29.9341 10.4999C31.8471 10.6795 34.63 11.3745 34.63 11.3745V7.72377C34.63 6.28014 32.5176 5.10944 29.9118 5.10944C28.8931 5.10944 26.2847 5.8808 23.4854 6.82039ZM32.6702 10.1559C32.6702 10.1559 27.8461 8.28406 23.4854 6.82039C19.1246 8.28406 14.3005 10.1559 14.3005 10.1559C14.3005 10.1559 19.1573 10.2195 23.4867 10.3048C27.8154 10.2195 32.6702 10.1559 32.6702 10.1559Z" fill="none"/>
    </svg>
);

export default BedIcon;
