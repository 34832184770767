import { IconSVGProps } from "./types";

const ArrowRightIcon = ({ height = 24, width = 24, className }: IconSVGProps) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 -960 960 960"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M627-426H128v-106h499L403-756l76-76 353 353-351 351-76-76 222-222Z"/>
    </svg>
);

export default ArrowRightIcon;
