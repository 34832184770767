import { IconSVGProps } from "./types";

const PlayIcon = ({ height = 40, width = 40, className }: IconSVGProps) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 -960 960 960"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M382-306.667 653.333-480 382-653.333v346.666ZM480-80q-82.333 0-155.333-31.5t-127.334-85.833Q143-251.667 111.5-324.667T80-480q0-83 31.5-156t85.833-127q54.334-54 127.334-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82.333-31.5 155.333T763-197.333Q709-143 636-111.5T480-80Z"/>
    </svg>
);

export default PlayIcon;
