export enum SupportedLanguageEnum {
    Eng = 1,
    Hr = 2,
}

export enum LoginStateEnum {
    Uninitialized = 0,
    Pending = 1,
    LoggedIn = 2,
    Unauthorized = 3,
    Expired = 4,
}

export enum ModalTypeEnum {
    None = 0,
}

export enum ModalActionButtonTypeEnum {
    Confirm = 0,
    Delete = 1,
    Error = 2,
}

export enum EnrolmentTypeEnum { // Hardkodirano
    University = "University",
    StateExam = "State Exam",
}

export enum SecondaryEducationEnum { // Hardkodirano
    Croatia = "Croatia",
    EU = "EU",
    NonEU = "non-EU",
}

export enum EnrollmentStatusEnum { // Hardkodirano
    LongTerm = "Long Term",
    Transfer = "Transfer",
    Exchange = "Exchange",
}

export enum RoomateTypeEnum {
    Student = 1,
    NotAStudent = 2,
}

export enum HousingCapacityEnum {
    Single = "single",
    Double = "double",
    Triple = "triple",
}

export enum HousingSortByEnum {
    Price = 0,
    DistanceToUni = 1,
    Single = 2,
    Double = 3,
    Triple = 4,
}

export enum IUserRoleEnum {
    Student = "student",
    Landlord = "landlord",
    University = "uni",
    Admin = "admin",
}

export const housingCapacityEnumToCapacity = (value: HousingCapacityEnum): number => {
    switch (value) {
        case HousingCapacityEnum.Single:
            return 1;
        case HousingCapacityEnum.Double:
            return 2;
        case HousingCapacityEnum.Triple:
            return 3;
    }
}

export const capacityToHousingCapacityEnum = (value: number): HousingCapacityEnum => {
    switch (value) {
        case 1:
            return HousingCapacityEnum.Single;
        case 2:
            return HousingCapacityEnum.Double;
        case 3:
            return HousingCapacityEnum.Triple;
        default:
            throw new Error("Unmapped housing capacity: " + value)
    }
}
