import { IconSVGProps } from "./types";

const BathroomIcon = ({ height = 21, width = 21, className }: IconSVGProps) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M20.3766 10.0642H19.8185V2.68381C19.8185 1.20157 18.607 0 17.1126 0H16.0979C15.9111 0 15.7597 0.150177 15.7597 0.335461V2.04638C14.9722 2.20583 14.4066 2.89299 14.4068 3.69019C14.4068 3.87547 14.5582 4.02565 14.745 4.02565H17.4509C17.6377 4.02565 17.7891 3.87547 17.7891 3.69019C17.7893 2.89299 17.2237 2.20579 16.4362 2.04638V0.670961H17.1126C18.2335 0.670961 19.1421 1.57214 19.1421 2.68381V10.0642H0.99568C0.74351 10.0642 0.539062 10.267 0.539062 10.5171V10.9532C0.539062 11.2033 0.74351 11.4061 0.99568 11.4061H20.3766C20.6287 11.4061 20.8332 11.2033 20.8332 10.9532V10.5171C20.8332 10.267 20.6288 10.0642 20.3766 10.0642Z" fill="none"/>
        <path d="M19.6326 12.0771H1.74C1.64471 12.077 1.56742 12.1535 1.56738 12.248C1.56738 12.2503 1.56742 12.2526 1.5675 12.2549C1.69941 14.6434 2.74456 17.1159 4.27336 17.8774C3.76263 18.4184 3.79082 19.2675 4.33622 19.7741C4.88166 20.2807 5.73782 20.2527 6.24854 19.7118C6.65819 19.2778 6.73116 18.6283 6.4279 18.1156H14.9345C14.7366 18.4433 14.6933 18.8405 14.8161 19.2025C15.0544 19.9049 15.8217 20.2827 16.5299 20.0463C17.2381 19.81 17.619 19.049 17.3807 18.3466C17.3193 18.1659 17.2199 18.0002 17.089 17.8606C18.628 17.0991 19.6732 14.6266 19.7949 12.238C19.79 12.1513 19.7201 12.082 19.6326 12.0771Z" fill="none"/>
    </svg>
);

export default BathroomIcon;
