import { IconSVGProps } from "./types";

const HamburgerMenuIcon = ({ height = 40, width = 40, className }: IconSVGProps) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 -960 960 960"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M101-208.667v-104.666h758v104.666H101Zm0-219v-104.666h758v104.666H101Zm0-219V-752h758v105.333H101Z"/>
    </svg>
);

export default HamburgerMenuIcon;
