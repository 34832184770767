import { IconSVGProps } from "./types";

const CheckCircleIcon = ({ height = 24, width = 24, className }: IconSVGProps) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 -960 960 960"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="m424-276 304-304-74-74-230 230-116-116-74 74 190 190Zm56 228q-89.64 0-168.48-34.02-78.84-34.02-137.16-92.34-58.32-58.32-92.34-137.16T48-480q0-89.896 34.079-168.961 34.079-79.066 92.5-137.552Q233-845 311.738-878.5 390.476-912 480-912q89.886 0 168.943 33.5Q728-845 786.5-786.5q58.5 58.5 92 137.583 33.5 79.084 33.5 169Q912-390 878.5-311.5t-91.987 136.921q-58.486 58.421-137.552 92.5Q569.896-48 480-48Z"/>
    </svg>
);

export default CheckCircleIcon;
