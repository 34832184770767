import { IconSVGProps } from "./types";

const AboutBackground = ({ height = 541, width = 2462, className }: IconSVGProps) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M570.661 481.635L49.6901 470.66C-11.3221 469.374 -17.2905 380.727 42.9987 371.274L2404.26 1.05378C2434.59 -3.70196 2462 19.748 2462 50.4503L2462 490.379C2462 518.618 2438.63 541.254 2410.41 540.353L570.661 481.635Z" fill="url(#paint0_linear_1458_3901)"/>
        <defs>
        <linearGradient id="paint0_linear_1458_3901" x1="1532.59" y1="429.695" x2="3283.93" y2="429.695" gradientUnits="userSpaceOnUse">
        <stop stop-color="#123075"/>
        <stop offset="1" stop-color="#182553"/>
        </linearGradient>
        </defs>
    </svg>
);

export default AboutBackground;
