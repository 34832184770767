import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    HousingPreview, INotification,
    IUpdateUserRequest,
    UniversityDocument,
    User,
    UserResidencyDocument,
} from "redux/models/userModels";
import { executeAxiosRequest } from "redux/services";

export const getUserThunk = createAsyncThunk<User>("user/getUser", async () => {
    const response = await executeAxiosRequest({
        url: "/users/me",
        method: "GET",
    });

    return response.data;
});

export const getDocumentsThunk = createAsyncThunk<UserResidencyDocument[]>("user/get-documents", async () => {
    const response = await executeAxiosRequest({
        url: `/users/residency-documents`,
        method: "GET",
    });

    return response.data;
});

export const getUniDocumentsThunk = createAsyncThunk<UniversityDocument[]>("user/get-uni-documents", async () => {
    const response = await executeAxiosRequest({
        url: `/universities/documents/user`,
        method: "GET",
    });

    return response.data;
});

export const getFavoriteHousingsThunk = createAsyncThunk<HousingPreview[]>("user/get-favorite", async () => {
    const response = await executeAxiosRequest({
        url: "/housings/favorites",
        method: "GET",
    });

    return response.data;
});

export const toggleFavoriteHousingThunk = createAsyncThunk<void, { submit: boolean; housingId: number }>(
    "user/add-favorite",
    async data => {
        await executeAxiosRequest({
            url: `/housings/${data.housingId}/favorites`,
            method: data.submit ? "POST" : "DELETE",
        });
    }
);

export const updateUserThunk = createAsyncThunk<User, IUpdateUserRequest>("user/update-user", async data => {
    const response = await executeAxiosRequest({
        url: `/users/me`,
        method: "PUT",
        data: data,
    });

    return response.data;
});

type SubmitDocRequest = {
    docId: number;
    docType: string;
    checked: boolean;
};

export const submitDocumentThunk = createAsyncThunk<void, SubmitDocRequest>("user/submit-doc", async (data, api) => {
    await executeAxiosRequest({
        url:
            data.docType === "UNI"
                ? `/universities/documents/${data.docId}`
                : `/users/residency-documents/${data.docId}`,
        method: "PATCH",
        data: { submitted: data.checked },
    });
});

export const getNotificationsThunk = createAsyncThunk<INotification[]>("user/notifications", async (data, api) => {
    const response = await executeAxiosRequest({
        url: `/users/me/notifications`,
        method: "GET",
    });

    return response.data;
});
