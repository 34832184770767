import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getHousingDetailsThunk } from "redux/actions/housingAction";
import { IHousingData } from "redux/models/housingModels";
import { HousingCapacityEnum, HousingSortByEnum } from "utils/enums";
import { DateTime } from "luxon";

export interface IHousingState {
    activeHousing?: IHousingData;
    filters: {
        arrivalDate?: string;
        departureDate?: string;
        priceFrom: number;
        priceTo: number;
        capacity?: number;
    };
}

const initialState: IHousingState = {
    activeHousing: undefined,
    filters: {
        priceFrom: 0,
        priceTo: 1000,
        // capacity: HousingCapacityEnum.Single,
        arrivalDate: DateTime.now().toFormat("yyyy-MM-dd"),
        departureDate: DateTime.now().plus({ month: 12 }).toFormat("yyyy-MM-dd"),
    },
};

export const housingReducer = createSlice({
    name: "housing",
    initialState,
    reducers: {
        changeHousingFilters(state: IHousingState, action: PayloadAction<any>) {
            state.filters = {
                ...state.filters,
                ...action.payload,
            };
        },
    },
    extraReducers: builder => {
        builder.addCase(
            getHousingDetailsThunk.fulfilled,
            (state: IHousingState, action: PayloadAction<IHousingData>) => {
                state.activeHousing = action.payload;
            }
        );
    },
});

const { reducer } = housingReducer;
export const { changeHousingFilters } = housingReducer.actions;
export default reducer;
