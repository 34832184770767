import { IconSVGProps } from "./types";

const KitchenIcon = ({ height = 18, width = 21, className }: IconSVGProps) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M7.02041 11.245C6.30153 11.2385 5.58171 11.245 4.85908 11.245C5.58171 11.2441 6.30529 11.2385 7.02041 11.245Z" fill="white"/>
        <path d="M11.0629 6.17985C11.8062 6.18641 12.5495 6.17985 13.2928 6.17985C13.3347 6.17633 13.3768 6.18179 13.4163 6.19586C13.4559 6.20993 13.492 6.23229 13.5221 6.26143C13.8802 6.60086 14.3199 6.73776 14.8048 6.74151C15.3837 6.74151 15.9625 6.74151 16.5414 6.74151C17.1203 6.74151 17.7001 6.74995 18.2789 6.74151C18.7958 6.73213 19.2741 6.58961 19.619 6.17891C20.0832 5.63131 20.1875 5.00683 19.8915 4.34671C19.5955 3.68659 19.0176 3.38372 18.3118 3.37247C17.1306 3.35278 15.9494 3.36216 14.7682 3.37247C14.3252 3.36121 13.8946 3.5192 13.5644 3.81412C13.5204 3.8559 13.4682 3.88812 13.4111 3.90878C13.354 3.92944 13.2933 3.93806 13.2327 3.93413C12.5279 3.93413 11.8231 3.92476 11.1202 3.93413C10.9464 3.93413 10.8844 3.87976 10.844 3.71942C10.2303 1.31524 7.74291 -0.435386 5.01212 0.0953335C2.04545 0.672936 0.294777 3.55719 1.11608 6.46583C1.44173 7.64454 2.19219 8.66172 3.22343 9.32211C4.25467 9.98249 5.49403 10.2396 6.70359 10.044C8.84895 9.70735 10.2519 8.43212 10.8618 6.33081C10.8966 6.21736 10.9379 6.17891 11.0629 6.17985ZM5.97344 8.43025C5.30403 8.43173 4.64924 8.23502 4.09193 7.86501C3.53462 7.49499 3.09984 6.96832 2.84264 6.35164C2.58544 5.73497 2.51738 5.05601 2.64706 4.40071C2.77674 3.74542 3.09833 3.14324 3.57114 2.6704C4.04396 2.19756 4.64673 1.87532 5.30316 1.74447C5.95959 1.61361 6.64018 1.68003 7.25877 1.9353C7.87736 2.19057 8.40617 2.62322 8.77823 3.17849C9.15028 3.73377 9.34887 4.3867 9.34887 5.05465C9.35035 5.49781 9.26414 5.9369 9.09515 6.34673C8.92616 6.75657 8.67773 7.12907 8.36412 7.44287C8.05051 7.75667 7.67788 8.00559 7.26763 8.17535C6.85737 8.34512 6.41757 8.43237 5.97344 8.43213V8.43025Z" fill="none"/>
        <path d="M18.6935 12.3627C17.4568 12.374 16.2192 12.3684 14.9826 12.3684C13.7459 12.3684 12.4942 12.3684 11.2491 12.3684C11.0941 12.3684 11.0349 12.3965 11.0358 12.5681C11.0443 13.7871 11.0358 15.006 11.0424 16.225C11.0489 17.2714 11.7697 17.9897 12.8175 17.9944C14.2653 18.0019 15.7134 18.0019 17.1618 17.9944C18.2142 17.9944 18.9228 17.2724 18.9256 16.2212C18.9256 15.0182 18.9256 13.8161 18.9256 12.6131C18.9322 12.4199 18.8974 12.3609 18.6935 12.3627Z" fill="none"/>
        <path d="M5.48115 15.7346C5.9839 15.7234 6.40584 15.4215 6.48008 14.9395C6.5327 14.5982 6.6915 14.5963 6.93394 14.6179C6.97247 14.6179 7.01194 14.6179 7.05141 14.6179C8.04656 14.5907 8.78237 13.8677 8.78049 12.9301C8.77861 11.9924 8.02872 11.2573 7.02041 11.2479C6.30153 11.2413 5.58172 11.2479 4.85908 11.2479H2.77008C2.20626 11.2479 2.01832 11.4298 2.01832 11.9849C2.01832 12.8601 2.01832 13.7352 2.01832 14.6104C2.01832 15.2452 2.44589 15.7253 3.08395 15.7412C3.88459 15.7543 4.68334 15.7506 5.48115 15.7346ZM6.59002 12.3815C6.79393 12.2512 7.40287 12.3984 7.52973 12.5691C7.60544 12.669 7.64833 12.7899 7.65254 12.9151C7.65674 13.0403 7.62206 13.1638 7.55322 13.2686C7.44703 13.4308 7.28353 13.4749 7.11062 13.4936C6.52236 13.5564 6.52236 13.5546 6.52236 12.931C6.51953 12.8998 6.51953 12.8684 6.52236 12.8373C6.55619 12.6807 6.43872 12.4781 6.58719 12.3815H6.59002Z" fill="none"/>
        <path d="M19.1032 9.55725C18.0864 9.55725 17.0696 9.55163 16.0529 9.55725C15.8649 9.55725 15.8123 9.51317 15.8226 9.32752C15.8331 9.21187 15.8188 9.09533 15.7807 8.98562C15.7425 8.87591 15.6814 8.77555 15.6014 8.69121C15.5214 8.60688 15.4244 8.54048 15.3167 8.49645C15.209 8.45241 15.0932 8.43173 14.9769 8.43579C14.5071 8.42454 14.1415 8.81087 14.1434 9.31908C14.1434 9.51693 14.0917 9.56007 13.8991 9.55819C12.8823 9.54788 11.8655 9.54881 10.8488 9.55819C10.2154 9.55819 9.7991 10.0505 9.94382 10.6046C10.0519 11.0144 10.3733 11.2422 10.8638 11.2432C12.242 11.2432 13.6203 11.2432 14.9985 11.2432C16.3768 11.2432 17.7456 11.2432 19.105 11.2432C19.6895 11.2432 20.0636 10.8953 20.0532 10.3768C20.0419 9.88543 19.6689 9.55913 19.1032 9.55725Z" fill="none"/>
        <path d="M7.83139 16.3057C6.3147 16.3057 4.79801 16.3057 3.28132 16.3057C2.78891 16.3057 2.29651 16.2982 1.8041 16.3057C1.19047 16.3178 0.785451 16.8129 0.931106 17.3568C1.04105 17.7656 1.36148 17.9888 1.85483 17.9897C2.8481 17.9897 3.84045 17.9897 4.83372 17.9897C5.827 17.9897 6.83436 17.9897 7.83515 17.9897C8.41777 17.9897 8.79178 17.6409 8.78051 17.1224C8.77017 16.6329 8.39522 16.3066 7.83139 16.3057Z" fill="none"/>
        <path d="M5.96699 1.68464C5.2979 1.68464 4.64384 1.88262 4.08752 2.25354C3.5312 2.62445 3.0976 3.15165 2.84156 3.76846C2.58551 4.38527 2.51852 5.06399 2.64905 5.71879C2.77958 6.3736 3.10176 6.97507 3.57488 7.44716C4.04799 7.91924 4.65078 8.24074 5.30701 8.37099C5.96324 8.50123 6.64343 8.43439 7.26159 8.1789C7.87974 7.92341 8.4081 7.49075 8.77983 6.93563C9.15155 6.38052 9.34994 5.72787 9.34994 5.06024C9.35118 4.61661 9.26453 4.17711 9.09496 3.767C8.92539 3.3569 8.67625 2.98428 8.36187 2.67058C8.04749 2.35688 7.67406 2.10828 7.26306 1.93908C6.85206 1.76988 6.41159 1.68341 5.96699 1.68464ZM5.97545 7.29565C4.69181 7.29565 3.73988 6.31672 3.72015 5.05274C3.70323 3.84222 4.74726 2.80235 5.96325 2.81641C6.2597 2.81542 6.5534 2.87295 6.82748 2.98569C7.10155 3.09843 7.35058 3.26416 7.5602 3.47333C7.76982 3.6825 7.9359 3.93097 8.04889 4.20445C8.16188 4.47793 8.21954 4.77101 8.21855 5.06681C8.20445 6.23046 7.30984 7.29283 5.97545 7.29565Z" fill="none"/>
        <path d="M6.52797 12.8335C6.52515 12.8647 6.52515 12.8961 6.52797 12.9273C6.52797 13.5508 6.52795 13.5527 7.11621 13.4899C7.28912 13.4711 7.45265 13.427 7.55883 13.2648C7.62767 13.16 7.66235 13.0366 7.65815 12.9114C7.65394 12.7861 7.61106 12.6653 7.53534 12.5653C7.40378 12.3984 6.79485 12.2512 6.59563 12.3778C6.4387 12.4781 6.55617 12.6807 6.52797 12.8335Z" fill="none"/>
        <path d="M5.96417 2.81639C4.74818 2.80045 3.70885 3.8422 3.72107 5.05272C3.73986 6.3167 4.69179 7.30313 5.97637 7.29562C7.31076 7.29562 8.20537 6.23043 8.21759 5.06397C8.21821 4.76856 8.16033 4.47595 8.04729 4.20292C7.93425 3.92989 7.76828 3.68183 7.5589 3.47299C7.34951 3.26414 7.10115 3.09837 6.82748 2.98569C6.5538 2.87301 6.26022 2.81565 5.96417 2.81639Z" fill="none"/>
</svg>
);

export default KitchenIcon;
