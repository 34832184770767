import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    createAccountThunk,
    createRoomateAccountThunk,
    getAuthenticationThunk,
    userLoginThunk
} from "redux/actions/authAction";
import {ICreateAccountState, LoadingActionModel, LoggedUser} from "redux/models/authModels";
import {LoginStateEnum} from "utils/enums";
import {UserType} from "../models/userModels";
import {act} from "react-dom/test-utils";

const initialLoggedUser: Readonly<LoggedUser> = {
    id: 0,
    type: UserType.BASIC,
    username: "",
    roles: []
}
export interface IAuthState {
    loadingActions: Array<LoadingActionModel>;
    loginState: LoginStateEnum;
    loggedUser: LoggedUser;
    createAccountData: ICreateAccountState;
}

const initialState: IAuthState = {
    loadingActions: [],
    loginState: LoginStateEnum.Uninitialized,
    loggedUser: initialLoggedUser,
    createAccountData: {},
};

export const authReducer = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setCreateAccountData(state: IAuthState, action: PayloadAction<ICreateAccountState>) {
            state.createAccountData = {
                ...state.createAccountData,
                ...action.payload,
            };
        },
        clearCreateAccountData(state: IAuthState) {
            state.createAccountData = {};
        },
        addLoaderAction(state: IAuthState, action: PayloadAction<LoadingActionModel>) {
            state.loadingActions.push(action.payload);
        },
        removeLoaderAction(state: IAuthState, action: PayloadAction<string>) {
            state.loadingActions = state.loadingActions.filter(x => x.requestId != action.payload);
        },
    },
    extraReducers: builder => {
        builder.addCase(userLoginThunk.pending, state => {});
        builder.addCase(userLoginThunk.fulfilled, state => {
            state.loginState = LoginStateEnum.LoggedIn;
        });
        builder.addCase(userLoginThunk.rejected, state => {
            state.loginState = LoginStateEnum.Unauthorized;
            state.loggedUser = initialLoggedUser;
        });

        builder.addCase(getAuthenticationThunk.fulfilled, (state, action) => {
            state.loginState = LoginStateEnum.LoggedIn
            state.loggedUser = action.payload
        }).addCase(getAuthenticationThunk.rejected, state => {
            state.loginState = LoginStateEnum.Unauthorized;
            state.loggedUser = initialLoggedUser;
        })

        builder.addCase(createRoomateAccountThunk.rejected, (state, action) => {
            state.createAccountData.errorMessage = action.error.message;
        });
        builder.addCase(createAccountThunk.rejected, (state, action) => {
            state.createAccountData.errorMessage = action.error.message;
        });
    },
});

const { reducer } = authReducer;
export const { addLoaderAction, removeLoaderAction, setCreateAccountData, clearCreateAccountData } =
    authReducer.actions;
export default reducer;
