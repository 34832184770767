import { IconSVGProps } from "./types";

const SquareMetersIcon = ({ height = 18, width = 18, className }: IconSVGProps) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fillRule="evenodd" clipRule="evenodd" d="M17.7707 3.16358C17.7707 3.41318 17.5667 3.61551 17.315 3.61551H16.4037V14.0101H17.315C17.5667 14.0101 17.7707 14.2124 17.7707 14.462V17.1737C17.7707 17.4233 17.5667 17.6256 17.315 17.6256H14.5811C14.3294 17.6256 14.1254 17.4233 14.1254 17.1737V16.2698H3.64527V17.1737C3.64527 17.4233 3.44127 17.6256 3.18961 17.6256H0.455659C0.203998 17.6256 0 17.4233 0 17.1737V14.462C0 14.2125 0.203998 14.0101 0.455659 14.0101H1.36698V3.61551H0.455659C0.203998 3.61551 0 3.41318 0 3.16358V0.451939C0 0.202332 0.203998 0 0.455659 0H3.18961C3.44127 0 3.64527 0.202332 3.64527 0.451939V1.35582H14.1254V0.451939C14.1254 0.202332 14.3294 0 14.581 0H17.315C17.5667 0 17.7707 0.202332 17.7707 0.451939V3.16358ZM14.5804 14.01H15.4917H15.4917V3.61544H14.5804C14.3287 3.61544 14.1247 3.41311 14.1247 3.1635V2.25962H3.64458V3.1635C3.64458 3.41311 3.44059 3.61544 3.18893 3.61544H2.27761V14.01H3.18893C3.44059 14.01 3.64458 14.2123 3.64458 14.4619V15.3658H14.1247V14.4619C14.1247 14.2123 14.3287 14.01 14.5804 14.01Z" fill="none"/>
    </svg>
);

export default SquareMetersIcon;
