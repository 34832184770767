import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    IChangePasswordRequest,
    ICreateAccountRequest,
    ICreateAccountRoomateFirstStepRequest,
    ICreateRoomateAccountRequest,
    IForgotPasswordRequest,
    ILoginFormRequest,
    IResetPasswordRequest,
    LoggedUser,
    UserTokenMetadata,
} from "redux/models/authModels";
import {executeAxiosRequest} from "redux/services";
import {setUserLocalStorageData} from "utils/storageActions";

export const userLoginThunk = createAsyncThunk<UserTokenMetadata, ILoginFormRequest>("auth/userLogin", async data => {
    const response = await executeAxiosRequest({
        url: "/authenticate",
        method: "POST",
        data: data,
    });

    setUserLocalStorageData(response.data);

    return response.data;
});

export const getAuthenticationThunk = createAsyncThunk<LoggedUser>("auth/getAuthentication", async () => {
    const response = await executeAxiosRequest({
        url: "/authenticate",
        method: "GET"
    })

    return response.data;
})

export const changePasswordThunk = createAsyncThunk<void, IChangePasswordRequest>("auth/changePassword", async data => {
    await executeAxiosRequest({
        url: "/account/change-password",
        method: "POST",
        data: data,
    });
});

export const forgotPasswordThunk = createAsyncThunk<void, IForgotPasswordRequest>("auth/forgotPassword", async data => {
    await executeAxiosRequest({
        url: "/account/reset-password/init",
        method: "POST",
        data: data,
    });
});

export const resetPasswordThunk = createAsyncThunk<void, IResetPasswordRequest>("auth/resetPassword", async data => {
    await executeAxiosRequest({
        url: "/account/reset-password/finish",
        method: "POST",
        data: data,
    });
});

export const createAccountThunk = createAsyncThunk<any, ICreateAccountRequest>("auth/createAccount", async data => {
    const response = await executeAxiosRequest({
        // url: "/edudom/register",
        url: "/account/register-student-demo",
        method: "POST",
        data: data,
    });

    return response.data;
});

export const createRoomateAccountThunk = createAsyncThunk<
    any,
    ICreateRoomateAccountRequest | ICreateAccountRoomateFirstStepRequest
>("auth/createRoomateAccount", async data => {
    const response = await executeAxiosRequest({
        // url: "/edudom/register",
        url: "/account/register-student-demo",
        method: "POST",
        data: data,
    });

    return response.data;
});

export const contactUsThunk = createAsyncThunk<any, any>("auth/contactUs", async data => {
    const response = await executeAxiosRequest({
        url: "/email/contact-us",
        method: "POST",
        data: {
            text: data.message,
            from: data.email,
            name: data.name,
            phone: data.phone,
            subject: data.subject,
        },
    });

    return response.data;
});
