import { IconSVGProps } from "./types";

const WarningIcon = ({ height = 24, width = 24, className }: IconSVGProps) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 -960 960 960"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="m-12-78 492-848L972-78H-12Zm493-135q22 0 37.5-15.5T534-266q0-22-15.5-37.5T481-319q-22 0-37.5 15.5T428-266q0 22 15.5 37.5T481-213Zm-53-143h106v-204H428v204Z"/>
    </svg>
);

export default WarningIcon;
