import {UserTokenMetadata} from "../redux/models/authModels";

const JWT_TOKEN_STORAGE_KEY: string = "token";

export const getAuthorizationHeader = (): Record<string, string> | undefined => {
    const token = getUserLocalStorageData();

    if (!token) {
        return;
    }

    return {
        "Authorization": `${token.type} ${token.accessToken}`,
    };
};

export function getUserLocalStorageData(): UserTokenMetadata | undefined {
    const stored = localStorage.getItem(JWT_TOKEN_STORAGE_KEY);
    if (!stored) {
        return;
    }
    return JSON.parse(stored);
}

export function setUserLocalStorageData(data: UserTokenMetadata): void {
    localStorage.setItem( JWT_TOKEN_STORAGE_KEY, JSON.stringify(data));
}

export function removeUserLocalStorageData() {
    localStorage.removeItem(JWT_TOKEN_STORAGE_KEY);
}

export const setRedirectToLastRoute = (value: boolean) => {
    sessionStorage.setItem("redirectToLastRoute", value.toString());
};

export const getRedirectToLastRoute = (): boolean => {
    const stored = sessionStorage.getItem("redirectToLastRoute");
    return /true/i.test(stored || "true"); // default true
};
