import { BaseQueryFn, createApi } from "@reduxjs/toolkit/query/react";
import { executeAxiosRequest } from "redux/services";
import { PayfacAxiosRequestConfig } from "./requestModels";

const axiosBaseQuery = (): BaseQueryFn<PayfacAxiosRequestConfig, unknown, unknown> => async config => {
    return await executeAxiosRequest(config);
};

export const baseApi = createApi({
    reducerPath: "api",
    baseQuery: axiosBaseQuery(),
    endpoints: builder => ({}),
});

export const {} = baseApi;