import React from "react";
import { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../../redux/store";
import { LoginStateEnum } from "../../../utils/enums";
import { PATHS } from "../../../utils/routing/paths";
import { getRedirectToLastRoute, setRedirectToLastRoute } from "../../../utils/storageActions";
import { FallbackComponent } from "../fallbackComponent";
import authWrapperImage from "assets/images/auth-page-image.png";
import authWrapperMobileImage from "assets/images/auth-page-image-mobile.png";
import "./globalRoutesWrapper.scss";

interface GlobalRoutesWrapperProps {
    children: React.ReactNode;
    redirectLoggedIn: boolean;
}
const GlobalRoutesWrapper = ({ children, redirectLoggedIn }: GlobalRoutesWrapperProps) => {
    const loginState = useSelector((state: RootState) => state.auth.loginState);
    const location = useLocation();
    const navigate = useNavigate();
    const isLoggin = loginState == LoginStateEnum.LoggedIn;

    useEffect(() => {
        if (isLoggin && redirectLoggedIn) {
            const locationState = location.state as any;
            const redirect = getRedirectToLastRoute();

            if (redirect && locationState?.from) {
                navigate(locationState.from, { replace: true });
            } else {
                setRedirectToLastRoute(true);
                navigate(PATHS.Global.Dashboard);
            }
        }
    }, [loginState]);

    return (
        <main role="main" className="auth-wrapper">
            <div className="auth-wrapper__background-rectangle auth-wrapper__background-rectangle--alpha"></div>
            <div className="auth-wrapper__background-rectangle auth-wrapper__background-rectangle--beta"></div>

            <div className="auth-wrapper__content-wrap">
                <div className="auth-wrapper__content">
                    <Suspense fallback={<FallbackComponent />}>{children}</Suspense>
                </div>
                <div className="auth-wrapper__image-wrap">
                    <img src={authWrapperImage} alt="Edudom" className="auth-wrapper__image auth-wrapper__image--big" />
                    <img
                        src={authWrapperMobileImage}
                        alt="Edudom"
                        className="auth-wrapper__image auth-wrapper__image--small"
                    />
                    <div className="auth-wrapper__image-overlay"></div>
                </div>
            </div>
        </main>
    );
};

export default GlobalRoutesWrapper;
