import { IconSVGProps } from "./types";

const CancelIcon = ({ height = 40, width = 40, className }: IconSVGProps) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 -960 960 960"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M251.333-178 178-251.333 406.667-480 178-708.667 251.333-782 480-553.333 708.667-782 782-708.667 553.333-480 782-251.333 708.667-178 480-406.667 251.333-178Z"/>
    </svg>
);

export default CancelIcon;
