import {createAsyncThunk} from "@reduxjs/toolkit";
import {IGetUniversityProgramsRequest, UniversityPreview, UniversityProgram} from "redux/models/universityModels";
import {executeAxiosRequest} from "redux/services";

export const getUniversitiesData = createAsyncThunk<UniversityPreview[]>("university/getUniversitiesData", async () => {
    const response = await executeAxiosRequest({
        url: "/universities/all",
        method: "GET",
    });

    return response.data;
});

export const getUniversitiesProgramData = createAsyncThunk<
    UniversityProgram[],
    IGetUniversityProgramsRequest
>("university/getUniversitiesProgramData", async data => {
    const response = await executeAxiosRequest({
        url: `/universities/${data.universityId}/programs`,
        method: "GET",
    });

    return response.data;
});
