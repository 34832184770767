import React from "react";
import { PATHS } from "./paths";
import { StringResources } from "utils/language/languageResource";
const LoginLazy = React.lazy(() => import("../../pages/login/login"));
const ForgotPasswordLazy = React.lazy(() => import("../../pages/forgotPassword/forgotPassword"));
export const ResetPasswordLazy = React.lazy(() => import("../../pages/resetPassword/resetPassword"));
const CreateAccountLazy = React.lazy(() => import("../../pages/createAccount/createAccount"));
const CreateRoomateAccountLazy = React.lazy(
    () => import("../../pages/createAccount/createRoomateAccount/createRoomateAccount")
);
const CreateAccountSecondStepLazy = React.lazy(
    () => import("../../pages/createAccount/createAccountSecondStep/createAccount")
);
const DashboardLazy = React.lazy(() => import("../../pages/dashboard/dashboard"));
const HousingLazy = React.lazy(() => import("../../pages/housing/housing"));
const HousingDetailsLazy = React.lazy(() => import("../../pages/housingDetails/housingDetails"));
const HousingReservationLazy = React.lazy(() => import("../../pages/housingReservation/housingReservation"));
const ContactUsLazy = React.lazy(() => import("../../pages/contactUs/contactUs"));
const AboutUsLazy = React.lazy(() => import("../../pages/aboutUs/aboutUs"));
const PrivacyLazy = React.lazy(() => import("../../pages/privacy/privacy"));
const TermsLazy = React.lazy(() => import("../../pages/terms/terms"));
const RentAgreementLazy = React.lazy(() => import("../../pages/rentAgreement/rentAgreement"));
export const StudentProfile = React.lazy(() => import("pages/studentProfile/studentProfile"));
export const UniversityProfile = React.lazy(() => import("pages/universityProfile/universityProfile"));
export const LandlordProfile = React.lazy(() => import("pages/landlordProfile/landlordProfile"));
export const ChangePassword = React.lazy(() => import("pages/changePassword/changePassword"));
const AdminPage = React.lazy(() => import('../../features/AdminPage'))

export const nonLoggedInElements = [
    {
        path: PATHS.NonLoggedIn.Login,
        element: LoginLazy,
    },
    {
        path: PATHS.NonLoggedIn.ForgotPassword,
        element: ForgotPasswordLazy,
    },
    {
        path: PATHS.NonLoggedIn.ResetPassword,
        element: ResetPasswordLazy,
        redirectLoggedIn: false,
    },
    {
        path: PATHS.NonLoggedIn.CreateAccount,
        element: CreateAccountLazy,
    },
    {
        path: PATHS.NonLoggedIn.CreateRoomateAccount,
        element: CreateRoomateAccountLazy,
    },
    {
        path: PATHS.NonLoggedIn.CreateAccountSecondStep,
        element: CreateAccountSecondStepLazy,
    },
];

export interface IPortalRouteElement {
    path: string;
    element: React.LazyExoticComponent<() => JSX.Element>;
}

export const PortalRouteElements: IPortalRouteElement[] = [
    {
        path: PATHS.Portal.Housing,
        element: HousingLazy,
    },
    {
        path: PATHS.Portal.HousingDetails,
        element: HousingDetailsLazy,
    },
    {
        path: PATHS.Portal.HousingReservation,
        element: HousingReservationLazy,
    },
    {
        path: PATHS.Portal.MyProfile,
        element: StudentProfile,
    },
    {
        path: PATHS.Portal.UniversityProfile,
        element: UniversityProfile,
    },
    {
        path: PATHS.Portal.LanlordProfile,
        element: LandlordProfile,
    },
    {
        path: PATHS.Portal.ChangePassword,
        element: ChangePassword,
    },
    {
        path: "/admin/*",
        element: AdminPage,
    },
];

export const GlobalRouteElements: IPortalRouteElement[] = [
    {
        path: PATHS.Global.Dashboard,
        element: DashboardLazy,
    },
    {
        path: PATHS.Global.ContactUs,
        element: ContactUsLazy,
    },
    {
        path: PATHS.Global.AboutUs,
        element: AboutUsLazy,
    },
    {
        path: PATHS.Global.Terms,
        element: TermsLazy,
    },
    {
        path: PATHS.Global.Privacy,
        element: PrivacyLazy,
    },
    {
        path: PATHS.Global.RentAgreement,
        element: RentAgreementLazy,
    },
];
