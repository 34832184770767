import { IconSVGProps } from "./types";

const CardIcon = ({ height = 24, width = 24, className }: IconSVGProps) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 -960 960 960"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M154-130q-43.725 0-74.862-31.137Q48-192.275 48-236v-488q0-43.725 31.138-74.862Q110.275-830 154-830h652q43.725 0 74.862 31.138Q912-767.725 912-724v488q0 43.725-31.138 74.863Q849.725-130 806-130H154Zm0-330h652v-202H154v202Z"/>
    </svg>
);

export default CardIcon;
