import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { Link, useLocation } from "react-router-dom";
import { StringResources } from "utils/language/languageResource";
import { useTranslation } from "react-i18next";
import logo from "assets/images/logo.png";
import { PATHS } from "utils/routing/paths";
import { FacebookIcon, InstagramIcon, LinkedInIcon } from "components/icons";
import { LoginStateEnum } from "utils/enums";
import "./appFooter.scss";

const AppFooter = () => {
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const authDataState = useSelector((state: RootState) => state.auth.loginState);

    //TODO: revisit this logic
    if (pathname === "/admin") {
        return <></>;
    }

    return (
        <footer className="app-footer">
            <div className="app-footer__container">
                <div className="app-footer__row">
                    <div className="col-md-5 col-lg-4 col-xl-3">
                        <div className="app-footer__logo-wrap">
                            <div className="app-footer__edudom_stylized_header">
                                <p>edu<span>dom</span></p>
                            </div>
                            <div className="app-footer__edudom_stylized_subheader">
                                Your home away from home...
                            </div>
                            <div className="app-footer__social-wrap">
                                <a href="#" target="_blank" className="app-footer__social-action">
                                    <InstagramIcon />
                                </a>

                                <a href="#" target="_blank" className="app-footer__social-action">
                                    <LinkedInIcon />
                                </a>

                                <a href="#" target="_blank" className="app-footer__social-action">
                                    <FacebookIcon />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7 col-lg-8 col-xl-9 app-footer__content">
                        <div className="app-footer__nav-wrap">
                            <div className="app-footer__nav">
                                <h3 className="app-footer__nav-title">{`${t(
                                    StringResources.footer.primaryNavTitle
                                )}`}</h3>

                                <ul className="app-footer__nav-list">
                                    <li className="app-footer__nav-list-item">
                                        <Link to={PATHS.Global.Dashboard} className="app-footer__nav-action">
                                            {`${t(StringResources.footer.primaryNavLinkHome)}`}
                                        </Link>
                                    </li>
                                    {authDataState === LoginStateEnum.LoggedIn && (
                                        <li className="app-footer__nav-list-item">
                                            <Link to={PATHS.Portal.Housing} className="app-footer__nav-action">
                                                {`${t(StringResources.footer.primaryNavLinkHousing)}`}
                                            </Link>
                                        </li>
                                    )}
                                    <li className="app-footer__nav-list-item">
                                        <Link to={PATHS.Global.AboutUs} className="app-footer__nav-action">
                                            {`${t(StringResources.footer.primaryNavLinkAbout)}`}
                                        </Link>
                                    </li>
                                    <li className="app-footer__nav-list-item">
                                        <Link to={PATHS.Global.ContactUs} className="app-footer__nav-action">
                                            {`${t(StringResources.footer.primaryNavLinkContact)}`}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 col-lg-11 col-xl-12 app-footer__content">
                        <div className="app_footer__nav_wrap">
                            <div className="app-footer__nav">
                                <h3 className="app-footer__nav-title">{`${t(
                                    StringResources.footer.secondaryNavTitle
                                )}`}</h3>

                                <ul className="app-footer__nav-list">
                                    <li className="app-footer__nav-list-item">
                                        <a href="mailto:ed@edudom.eu" className="app-footer__nav-action">
                                            ed@edudom.eu
                                        </a>
                                    </li>
                                    <li className="app-footer__nav-list-item">
                                        <a className="app-footer__nav-action">
                                            Edudom d.o.o.
                                        </a>
                                    </li>
                                    <li className="app-footer__nav-list-item">
                                        <a className="app-footer__nav-action">
                                            Ulica Svetog Mateja 85,
                                        </a>
                                    </li>
                                    <li className="app-footer__nav-list-item">
                                        <a className="app-footer__nav-action">
                                            HR-10000 Zagreb
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="app-footer__bottom-wrap">
               <p className="app-footer__bottom-text">
                   {`${t(StringResources.footer.bottomText) + " " + new Date().getFullYear()}`}
               </p>
            </div>
        </footer>
    );
};

export default AppFooter;
