import { createAsyncThunk } from "@reduxjs/toolkit";
import { IGetHousingDetailsRequest, IHousingData, IReserveHousingRequest } from "redux/models/housingModels";
import { executeAxiosRequest } from "redux/services";

export const getHousingDetailsThunk = createAsyncThunk<IHousingData, IGetHousingDetailsRequest>(
    "housing/details",
    async data => {
        const response = await executeAxiosRequest({
            url: `/housings/${data.housingId}`,
            method: "GET",
        });

        return response.data;
    }
);

export const housingReservationThunk = createAsyncThunk<any, IReserveHousingRequest>(
    "housing/reservation",
    async data => {
        const response = await executeAxiosRequest({
            url: `/housings/${data.housingId}/reservation`,
            method: "POST",
            data: data,
        });

        return response.data;
    }
);
