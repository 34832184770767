import { IconSVGProps } from "./types";

const ChevronRightIcon = ({ height = 24, width = 24, className }: IconSVGProps) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 -960 960 960"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="m376-240-56-56 184-184-184-184 56-56 240 240-240 240Z"/>
    </svg>
);

export default ChevronRightIcon;
