import { baseApi } from "api/baseApi";
import { modalSliceName } from "redux/reducers/modalReducer";

export const reduxAction = {
    resetStore: "store/reset",
};

export const ignoreLoader = [baseApi.reducerPath, modalSliceName];

export enum AppRoles {
    Student = "student",
    Landlord = "landlord",
    University = "university",
    admin = "admin",
}

export const AgencyFee = 50;

export const AgencyFeeCurrency = "HRK";

export const DefaultUtillities = 40;
