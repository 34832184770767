import axios from "axios";
import { toast } from "react-toastify";
import { devConsoleError, devConsoleLog } from "utils/helperFunctions";
import { appConfig } from "./app.config";
import {isApiServiceError} from "utils/apiServiceException";

axios.defaults.baseURL = appConfig.api.baseUrl + "/api/v1";

// Add a request interceptor
axios.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        devConsoleLog(`REQUEST : ${config.url}\n\nJSON : ${JSON.stringify(config)}`);

        return config;
    },
    function (error) {
        // Do something with request error
        devConsoleLog(`API request error : ${JSON.stringify(error)}`);

        return Promise.reject(error);
    }
);

// Add a response interceptor
axios.interceptors.response.use(
    function (response) {
        devConsoleLog(
            `RESPONSE : ${response.config.url} - Status : ${response.status} \n\nJSON : ${JSON.stringify(response)}`
        );

        return response;
    },
    function (error) {
        // Do something with response error
        if (error.response) {
            const {response} = error;
            devConsoleError(`API response error : ${JSON.stringify(response)}`);

            if (
                response?.config &&
                response?.config?.disableErrorToast !== true //&&
                // response?.status !== 401 &&
                // response?.status !== 403
            ) {
                if (isApiServiceError(response.data)) {
                    toast.error(response.data.error.message ?? response.data.error.error);
                } else {
                    toast.error("Something went wrong. Please contact support.")
                }
            }
        } else {
            devConsoleError(`API response error : ${JSON.stringify(error)}`);
        }

        return Promise.reject({
            name: error.name,
            message: error.response?.data ?? error.message,
            code: error.response?.status?.toString() ?? error.code,
            stack: error.stack,
        });
    }
);

export default axios;
