export const PATHS = {
    NonLoggedIn: {
        Login: "/login",
        ForgotPassword: "/forgot-password",
        ResetPassword: "/reset-password",
        CreateAccount: "/create-account",
        CreateRoomateAccount: "/create-roomate-account",
        CreateAccountSecondStep: "/create-account/second-step",
    },
    Portal: {
        // Mock routes
        Housing: "/housing",
        HousingDetails: "/housing/:id",
        HousingReservation: "/housing/:id/reservation",
        ChangePassword: "/change-password",
        MyProfile: "/my-profile",
        LanlordProfile: "/landlord-profile",
        UniversityProfile: "/university-profile",
    },
    Global: {
        Dashboard: "/",
        ContactUs: "/contact-us",
        AboutUs: "/about-us",
        Terms: "/terms-conditions",
        Privacy: "/privacy-policy",
        RentAgreement: "/rent-agreement",
    },
};
