import { IconSVGProps } from "./types";

const BellIcon = ({ height = 24, width = 24, className }: IconSVGProps) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M13.7,20h-3.5c-0.7,0-1.3,0.8-0.9,1.5C9.9,22.4,10.9,23,12,23s2.1-0.6,2.6-1.5C15,20.8,14.5,20,13.7,20z"/><path d="M21.8,16.7l-0.4-0.5C19.8,14.1,19,11.6,19,9V8.3c0-3.6-2.6-6.8-6.2-7.2C8.6,0.6,5,3.9,5,8v1c0,2.6-0.8,5.1-2.4,7.2 l-0.4,0.5c-0.2,0.2-0.3,0.6-0.2,0.8C2.3,18.4,3.1,19,4,19h16c0.9,0,1.7-0.6,1.9-1.5C22,17.2,21.9,16.9,21.8,16.7z"/>
    </svg>
);

export default BellIcon;
