import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    getDocumentsThunk,
    getFavoriteHousingsThunk,
    getNotificationsThunk,
    getUniDocumentsThunk,
    getUserThunk,
    updateUserThunk,
} from "redux/actions/userAction";
import {
    HousingPreview, INotification,
    isStudentUser,
    IUserState,
    UniversityDocument,
    User,
    UserResidencyDocument,
} from "redux/models/userModels";

const initialState: IUserState = {
    universityProfile: {
        preEntryDocuments: [],
    },
};

export const UserReducer = createSlice({
    name: "User",
    initialState,
    reducers: {
        removeNotification: (state: IUserState, action: PayloadAction<number>) => {
            if (state.notifications) {
                const index = state.notifications.findIndex(
                    notification => notification.id === action.payload
                );
                state.notifications.splice(index, 1);
            }
        },
        setProfileImageUrl: (state: IUserState, action: PayloadAction<string>) => {
            if (state.details) {
                state.details.profileImageUrl = action.payload
            }
        }
    },
    extraReducers: builder => {
        builder.addCase(updateUserThunk.fulfilled, (state: IUserState, {payload}: PayloadAction<User>) => {
            state.details = payload;
        })

        builder.addCase(getUserThunk.fulfilled, (state: IUserState, {payload}: PayloadAction<User>) => {
            state.details = payload;
        })

        builder.addCase(getDocumentsThunk.fulfilled, (state: IUserState, action: PayloadAction<UserResidencyDocument[]>) => {
            if (isStudentUser(state.details)) {
                state.details.residencyDocuments = action.payload
            }
            // state.documents = action.payload;
        });
        builder.addCase(
            getUniDocumentsThunk.fulfilled,
            (state: IUserState, action: PayloadAction<UniversityDocument[]>) => {
                // state.uniDocuments = action.payload;
                if (isStudentUser(state.details)) {
                    state.details.universityDocuments = action.payload
                }
                // state.uniDocuments = action.payload;
            }
        );

        builder.addCase(
            getFavoriteHousingsThunk.fulfilled,
            (state: IUserState, action: PayloadAction<HousingPreview[]>) => {
                // state.favorites = action.payload;
                if (isStudentUser(state.details)) {
                    state.details.housingFavorites = action.payload
                }
            }
        );

        builder.addCase(getNotificationsThunk.fulfilled, (state: IUserState, action: PayloadAction<INotification[]>) => {
            state.notifications = action.payload;
        });
    },
});

const { reducer } = UserReducer;
export const { removeNotification, setProfileImageUrl } = UserReducer.actions;
export default reducer;
