import React from "react";
import { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { PATHS } from "utils/routing/paths";
import {
    GlobalRouteElements,
    nonLoggedInElements,
    PortalRouteElements,
    ResetPasswordLazy,
} from "utils/routing/pathsAndElements";
import GlobalRoutesWrapper from "./wrappers/globalRoutesWrapper";
import { FallbackComponent } from "./fallbackComponent";
import PortalWrapper from "containers/layout/wrappers/portalWrapper";

const App = () => {
    return (
        <Suspense fallback={<FallbackComponent />}>
            <Routes>
                {nonLoggedInElements.map(element => {
                    return (
                        <Route
                            key={element.path}
                            path={element.path}
                            element={
                                <GlobalRoutesWrapper redirectLoggedIn={element.redirectLoggedIn ?? true}>
                                    <element.element />
                                </GlobalRoutesWrapper>
                            }
                        />
                    );
                })}
                {GlobalRouteElements.map(element => {
                    return (
                        <Route
                            key={element.path}
                            path={element.path}
                            element={
                                <PortalWrapper allowLoggedOut={true}>
                                    <element.element />
                                </PortalWrapper>
                            }
                        />
                    );
                })}
                {PortalRouteElements.map(element => {
                    return (
                        <Route
                            key={element.path}
                            path={element.path}
                            element={
                                <PortalWrapper allowLoggedOut={false}>
                                    <element.element />
                                </PortalWrapper>
                            }
                        />
                    );
                })}
            </Routes>
        </Suspense>
    );
};

export default App;
