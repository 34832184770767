import classNames from "classnames";
import "./baseButton.scss";

interface IBaseButtonProps {
    text?: string;
    onClick?: () => void;
    type?: "button" | "submit";
    styleType: "solid" | "outline";
    size?: "medium" | "small" | "full";
    disabled?: boolean;
    className?: string;
}

const BaseButton = ({
    text,
    onClick,
    disabled,
    type = "button",
    styleType = "solid",
    size = "medium",
    className,
}: IBaseButtonProps) => {
    const buttonTypeClass = `type--${styleType}`;
    const buttonSizeClass = `size--${size}`;
    return (
        <button
            type={type}
            disabled={disabled}
            className={classNames("base-button", buttonTypeClass, buttonSizeClass, className, {
                "base-button--disabled": disabled
            })}
            onClick={onClick}
        >
            {text && text}
        </button>
    );
};

export default BaseButton;
